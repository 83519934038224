import React, { useState } from "react";
import { Badge, Form, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ShimmerLoader from "../../_common/ShimmerLoader";

export default function UploadLog(props) {
  const [searchText, setSearchText] = useState("");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    const second = date.getSeconds().toString().padStart(2, "0");
    const formattedDate = `${day}${month}${year}${hour}${minute}${second}`;
    return `${formattedDate}`;
  };

  const columns = [
    {
      name: "Upload ID",
      selector: (row) => formatDate(row?.createdOn),
      sortable: true,
      cell: (row) => <span>{formatDate(row?.createdOn)}</span>,
    },
    {
      name: "Total Cases",
      selector: (row) => row?.totalCases,
      sortable: true,
      cell: (row) => (
        <Badge className="fs-14 badge-pill ligth-gray">{row?.totalCases}</Badge>
      ),
    },
    {
      name: "Uploaded On",
      selector: (row) => {
        new Date(row.createdOn).toLocaleString();
      },
      sortable: true,
      cell: (row) => (
        <h5 className="fs-14">{new Date(row.createdOn).toLocaleString()}</h5>
      ),
    },
    {
      name: "Uploaded By",
      selector: (row) => row.createdByName,
      sortable: true,
      cell: (row) => <span>{row?.createdByName}</span>,
    },
    {
      name: "Status",
      selector: (row) => row.statusDesc,
      sortable: true,
      cell: (row) => (
        <div className="d-flex align-items-center gap-2">
          <span>{row?.statusDesc}</span>
        </div>
      ),
    },
  ];

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const filteredData = props?.UploadeLogList?.filter((item) => {
    const searchTextLowerCase = searchText.toLowerCase();
    return (
      formatDate(item?.createdOn)?.includes(searchTextLowerCase) ||
      item?.totalCases?.toString()?.includes(searchTextLowerCase) ||
      new Date(item.createdOn)
        .toLocaleString()
        ?.toString()
        ?.includes(searchTextLowerCase) ||
      item?.createdByName?.toString()?.includes(searchTextLowerCase) ||
      item?.statusDesc?.toLowerCase()?.includes(searchTextLowerCase)
    );
  });

  return (
    <React.Fragment>
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-0">Upload Log</h4>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex justify-content-end">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          {props?.loading?.isLoading &&
          props?.loading?.value === "getUploadedLog" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
