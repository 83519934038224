import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { MultiSelect } from "react-multi-select-component";
import CreateNewGroup from "../UserGroups/create-new-group";
import ToastifyService from "../../_common/ToastifyService";
import Commonfield from "../../../infrastructure/core/Commonfield";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function UpdateUser(props) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [userRoleList, setUserRoleList] = useState([]);
  const [userGroupList, setUserGroupList] = useState([]);
  const [vendorList, setvendorList] = useState([]);
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [showNewGroup, SetShowNewGroup] = useState(false);
  const [selected, setSelected] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const userGroupListData = userGroupList?.map((userGroup) => ({
    label: userGroup?.groupName,
    value: userGroup?.id,
  }));
  const handleNewGroup = (state) => {
    SetShowNewGroup(state);
  };
  useEffect(() => {
    getUserRoleList();
    getUserGroupList();
    getvendorList();
    getTeamLeadList();
  }, []);
  useEffect(() => {
    if (props?.userId) {
      getuserDetails(props?.userId);
    }
  }, [props?.userId]);
  const getUserRoleList = async () => {
    try {
      const userRollListData = await axiosPrivate.get(`Users/user-roles`);
      setUserRoleList(userRollListData?.data);
    } catch (err) {
      console.error(err);
    }
  };
  const getUserGroupList = async () => {
    try {
      const userGroupListData = await axiosPrivate.get(`Users/user-groups`);
      setUserGroupList(userGroupListData?.data?.result);
    } catch (err) {
      console.error(err);
    }
  };
  const getvendorList = async () => {
    try {
      const vendorListData = await axiosPrivate.get(`Setting/get-vendors`);
      setvendorList(vendorListData?.data?.result);
    } catch (err) {
      console.error(err);
    }
  };
  const getTeamLeadList = async () => {
    try {
      const teamLeadListData = await axiosPrivate.get(`Users/team-leads`);
      setTeamLeadList(teamLeadListData?.data?.result);
    } catch (err) {
      console.error(err);
    }
  };
  const getuserDetails = async (Id) => {
    try {
      const userData = await axiosPrivate.get(`Users/user-details/${Id}`);
      setFormData(userData?.data?.result);
      setSelected(
        userData?.data?.result?.taskGroups?.map((taskGroup) => ({
          label: taskGroup.groupName,
          value: taskGroup.id,
        }))
      );
    } catch (err) {
      console.error(err);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleMultiSelectChange = (selectedOptions) => {
    setSelected(selectedOptions);
  };
  const UpdateUserDetails = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "UpdateUser",
      });
      const userListData = await axiosPrivate.post(`Users/update-user`, {
        ...formData,
        status: Commonfield.status.active,
        modifiedBy: auth?.id,
        taskGroup: selected?.map((groups) => groups?.value),
        teamLeadId:
          formData.role === Commonfield.userRoles.teamLead
            ? Commonfield.defaultValue
            : formData.teamLeadId,
      });
      props?.setUserAccountList(userListData?.data?.result);
      props?.setUpdateUser({ showBool: false, userId: "" });
      ToastifyService.success("User Details Updated Successfully");
    } catch (err) {
      // console.error(err);
      ToastifyService.error("Oops! Something Went Wrong");
      setLoading({
        isLoading: false,
        value: "UpdateUser",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "UpdateUser",
      });
    }
  };

  return (
    <React.Fragment>
      <CreateNewGroup
        show={showNewGroup}
        closeFunction={handleNewGroup}
        setUserGroupList={setUserGroupList}
      />
      <Offcanvas show={props.show} placement="end" className="w-40"  onHide={() => props?.setUpdateUser({ showBool: false, userId: "" })}>
        <Offcanvas.Header>
          <Offcanvas.Title className="fs-16 text-dark">Update User Details</Offcanvas.Title>
          <button type="button" class="btn-close" aria-label="Close" onClick={() => props?.setUpdateUser({ showBool: false, userId: "" })}></button>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Full Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Full Name"
                  name="fullName"
                  onChange={handleChange}
                  value={formData.fullName}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Role <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="role"
                  onChange={handleChange}
                  value={formData.role}
                >
                  <option selected disabled value={Commonfield.defaultValue}>
                    Select Role
                  </option>
                  {userRoleList?.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
          {formData.role !== Commonfield.userRoles.teamLead && (
            <Row>
              <Col xl={6}>
                <div className="mb-4">
                  <Form.Label>
                    Team Leader <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="teamLeadId"
                    onChange={handleChange}
                    value={formData.teamLeadId}
                  >
                    <option selected disabled value={Commonfield.defaultValue}>
                      Select Team Leader
                    </option>
                    {teamLeadList?.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.fullName}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Team <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="vendorId"
                  onChange={handleChange}
                  value={formData.vendorId}
                >
                  <option selected disabled value={Commonfield.defaultValue}>
                    Select Vendor
                  </option>
                  {vendorList?.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.vendorName}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Form.Label>
                User Group
              </Form.Label>
            </Col>
            <Col xl={6}>
              <div>
                <MultiSelect
                  name="taskGroups"
                  options={userGroupListData}
                  value={selected}
                  onChange={handleMultiSelectChange}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </div>
            </Col>

            <Col xl={6}>
              <Button
                type="button"
                variant="default"
                className="d-flex align-items-center gap-1"
                onClick={() => handleNewGroup(true)}
              >
                <i className="ri-add-line fs-18 lh-1"></i>
                <span className="d-sm-inline">Add New</span>
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "UpdateUser"}
            onClick={UpdateUserDetails}
          >
            {loading.isLoading && loading?.value === "UpdateUser" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              <>
                <span className="align-middle">Update</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
