import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default function ClaimInformation(props) {
  return (
    <React.Fragment>
      <div className="claim-information-wrapper">
        <Card className="mb-3">
          <span className="divider-name">Insurance Details</span>
          <Card.Body className="pt-4 pb-4">
            <Row className="gap-4">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Billed Payer</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.lastBilledPayer}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Billed Payer FC</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.lastBilledPayerFC}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Policy ID</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.policyId}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <span className="divider-name">Provider Details</span>
          <Card.Body className="pt-4 pb-4">
            <Row className="gap-4">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Refering Provider Name</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.referringProvider}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Service Location</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.serviceLocation}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <span className="divider-name">Claim</span>
          <Card.Body className="pt-4">
            <Row className="gap-4">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Adjustment</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.adjustment}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Insurance Payment</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.insurancePayment}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Patient Payment</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.patientPayment}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Current Balace</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.currentBalance}
                </h4>
              </Col>

              <Col xl={2}>
                <h5 className="fs-14 mb-1">Insurance Balace</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.insuranceBalance}
                </h4>
              </Col>

              <Col xl={2}>
                <h5 className="fs-14 mb-1">Patient Balance</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.patientBalance}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">First Bill Date</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.firstBillDate === "0001-01-01T00:00:00"
                    ? "-"
                    : new Date(
                        props?.claimInfo?.firstBillDate
                      ).toLocaleString()}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Bill Date</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.lastBillDate === "0001-01-01T00:00:00"
                    ? "-"
                    : new Date(props?.claimInfo?.lastBillDate).toLocaleString()}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <span className="divider-name">Denial/Correspondence</span>
          <Card.Body className="pt-4 pb-4">
            <Row className="gap-4">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Denial Category</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.denialCategory}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Denial date </h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.lastDenialDate === "0001-01-01T00:00:00"
                              ? "-"
                              : new Date(
                                props?.claimInfo?.lastDenialDate
                                ).toLocaleString()}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <span className="divider-name">History</span>
          <Card.Body className="pt-4">
            <Row className="gap-4">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Action Date</h5>
                <h4 className="fw-bold text-dark fs-14">
   
                  {props?.claimInfo?.lastActionDate === "0001-01-01T00:00:00"
                              ? "-"
                              : new Date(
                                props?.claimInfo?.lastActionDate
                                ).toLocaleString()}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Status</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.lastStatus}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Sub status</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.lastSubStatus}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Action </h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.lastAction}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Action Mode</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.claimInfo?.lastActionMode}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
