import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Form, ButtonGroup, Button } from "react-bootstrap";
import "./case-disposition.scss";
import Select from "react-select";
import actionMode from "../../../../Json/DecisionTree/action-mode.json";
import useAuth from "../../../../hooks/useAuth";
import { axiosPrivate } from "../../../../api/axios";
import ToastifyService from "../../../_common/ToastifyService";

function MedicalRecordsDispose(props) {
  const initialState = {
    statusName: "",
    submitType: "",
    dateOdDenial: "",
    claimId: "",
    FAXNumber: "",
    WebsiteLink: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    Zip: "",
    AttnTo: "",
    IsPolicyActive: "",
    ActionId: "",
    ActionModeId: "",
    AppealLimit: "",
    followUpBy: "",
    followUpDays: "",
  };
  const [formData, setFormData] = useState(initialState);
  const { auth } = useAuth();
  const [selectedSubStatus, setSelectedSubStatus] = useState("");
  const [selectedSubmitType, setSelectedSubmitTypes] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedActionMode, setSelectedActionMode] = useState("");
  const [subStatusList, setSubStatusList] = useState([]);
  const [submitList, setSubmitList] = useState([]);
  // HANDLE ACTION FUNCTION
  const [filteredActionData, setFilteredActionData] = useState([]);
  // HANDLE ACTION MODE FUNCTION
  const [filteredActionModeData, setFilteredActionModeData] = useState([]);
  const subStatusJson = [
    {
      value: 1,
      label: "Need to Submit MR",
    },
  ];

  const ActionJson = [
    {
      value: 1,
      label: "MR Sent",
    },
  ];
  const SubmitTypeJson = [
    {
      value: 1,
      label: "FAX",
    },
    {
      value: 2,
      label: "Website",
    },
    {
      value: 3,
      label: "Mail",
    },
  ];
  const RequiredDocsJson = [
    {
      value: 1,
      label: "Order Form",
    },
    {
      value: 2,
      label: "Result",
    },
    {
      value: 3,
      label: "Progress Notes",
    },
    {
      value: 4,
      label: "Claim Form",
    },
    {
      value: 5,
      label: "WOL",
    },
  ];
  const ActionModeJson = [
    {
      value: 1,
      label: "FAX",
      followUpdays: 22,
      followUPBy: "AR",
    },
    {
      value: 2,
      label: "WEB",
      followUpdays: 5,
      followUPBy: "AR",
    },
    {
      value: 3,
      label: "MAIL",
      followUpdays: 3,
      followUPBy: "AR-AUTH",
    },
  ];
  const handleActionSelect = (event) => {
    setFormData({
      ...formData,
      ActionId: event.value,
      ActionName: event.label,
      ActionModeId: "",
    });
    setSelectedAction(event);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      status: props.selectedStatus.value,
      statusName: props.selectedStatus.label,
    });
    setFilteredActionModeData(ActionModeJson);
    setSubStatusList([]);
    setSubmitList(SubmitTypeJson);
  }, [props.selectedStatus]);
  // STATUS FUNCTION

  // SUB STATUS FUNCTION
  const handleSubStatusSelect = (event) => {
    setFormData({
      ...formData,
      SubStatus: event.value,
      subStatusName: event.label,
    });
    setSelectedAction("");
    setSelectedSubStatus(event);
    if (formData.status !== props?.selectedStatus?.value) {
      const actionsList = JSON.parse(sessionStorage.getItem("commonField"))
        ?.find((wqty) => wqty.id === formData.status)
        ?.fieldValues?.find((action) => action.id === event.value)
        ?.commonFieldActions?.map((item) => ({
          value: item.id,
          label: item.actionName,
        }));
      setFilteredActionData(actionsList);
    } else {
      setFilteredActionData(ActionJson);
    }
  };
  const handleSubmitType = (event) => {
    setSelectedSubmitTypes(event);
    setFormData({
      ...formData,
      submitType: event.value,
      FAXNumber: "",
      WebsiteLink: "",
      Address1: "",
      Address2: "",
      City: "",
      State: "",
      Zip: "",
      AttnTo: "",
      ActionId: "",
      AppealLimit: "",
      ActionModeId: "",
      followUpBy: "",
      followUpDays: "",
    });
    setSubStatusList(subStatusJson);
  };
  const handleActionModeSelect = (event) => {
    setSelectedActionMode(event);
    setFormData({
      ...formData,
      ActionModeId: event.value,
      ActionModeName: event.label,
      followUpBy: event.followUPBy,
      followUpDays: event.followUpdays,
    });
  };

  const [checkboxes, setCheckboxes] = useState(
    RequiredDocsJson.reduce((acc, type) => {
      acc[type.label] = true;
      return acc;
    }, {})
  );

  // Handler to toggle the checkbox state
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    });
  };

  const handleSaveDisposition = async () => {
    
    const formObj = {
      caseId: props?.taskDetails?.id,
      visitId: props?.taskDetails?.visitId,
      dispositionBody: JSON.stringify(formData),
      dispositionRemark: stickyNoteContent,
      modifiedBy: auth.id,
    };
    try {
      const response = await axiosPrivate.post(
        "Task/case-disposition",
        formObj
      );
      props?.setPendingTasks(response.data.result.pendingTasks);
      props?.setTaskSummary(
        response?.data?.result?.taskBucketSummary?.taskBucketDetails
      );
      props?.setTeamLeadQueries(response?.data?.result?.tlQueries);
      props?.setParkedTasks(response?.data?.result?.parkedTasks);
      props?.setMyTask(response?.data?.result);
      props.setShowTasksDetails({
        showTaskDetails: false,
        taskId: "",
      });
      ToastifyService.success("Case Disposed SuccessFully");
    } catch (error) {
      console.error(error);
    }
  };
  console.log(checkboxes);
  const [stickyNoteContent, setStickyNoteContent] = useState('');
  const generateDynamicContent = () => {
    let content = '';

    content += `Visit Number ${props?.taskDetails?.visitId} `;
    if (formData.status) {
      content += `Claim Denied as ${formData.statusName}. `;
    }
    if (formData.dateOdDenial) {
      content += `On ${formData.dateOdDenial}. `;
    }
    if (formData.claimId) {
      content += `Claim # ${formData.claimId}. `;
    }
    if (formData.submitType === 1) {
      content += `Need to submit medical records By Fax. `;
      if (formData.FAXNumber) {
        content += `At Fax # ${formData.FAXNumber}. `;
      }
      if (formData.AttnTo) {
        content += `Attn. to. ${formData.AttnTo}. `;
      }
    } else if (formData.submitType === 2) {
      content += `Need to submit medical records by WEB. `;
      if (formData.WebsiteLink) {
        content += `At ${formData.WebsiteLink}. `;
      }
      if (formData.AttnTo) {
        content += `Attn. to. ${formData.AttnTo}. `;
      }
    } else if (formData.submitType === 3) {
      content += `Need to submit medical records by MAIL at ${formData.Address1} ${formData.Address2} ${formData.City} ${formData.State} ${formData.Zip}. `;
      if (formData.AttnTo) {
        content += `Attn. to. ${formData.AttnTo}. `;
      }
    }
    if (selectedSubmitType !== "") {
      content += "Selected options: ";
      Object.entries(checkboxes).forEach(([label, value]) => {
        if (value) {
          content += `${label}, `;
        }
      });
    }
    if (formData.AppealLimit) {
      content += `Need to Appeal. Appeal limit is ${formData.AppealLimit} Days. `;
    }
    if (formData.ActionId === 1) {
      content += `Claim Sent for MR. `;
    }

    // Update the state with the generated dynamic content
    setStickyNoteContent(content);
};


   // Call the function to generate the initial content when component mounts
 useEffect(() => {
  generateDynamicContent();
}, [props.taskDetails, formData]);
 
  return (
    <React.Fragment>
        <Col md={12}>
          {/* Denial Status Start */}

          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Date Of Denial:</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="date"
                name="dateOdDenial"
                value={formData.dateOdDenial}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    dateOdDenial: e.target.value,
                  })
                }
              ></Form.Control>
            </Col>
          </Row>
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Claim #:</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                placeholder="Enter Claim #"
                name="claimId"
                value={formData.claimId}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    claimId: e.target.value,
                  })
                }
              ></Form.Control>
            </Col>
          </Row>
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>
                How to Submit? :<span className="text-danger">*</span>
              </Form.Label>
            </Col>
            <Col>
              <Select
                onChange={handleSubmitType}
                options={submitList}
                name="submitType"
                value={selectedSubmitType}
                isClearable
              />
            </Col>
          </Row>

          {selectedSubmitType.value === 1 && (
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>FAX #:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter FAX Number"
                  name="FAXNumber"
                  value={formData.FAXNumber}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      FAXNumber: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          )}
          {selectedSubmitType.value === 2 && (
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Website Address:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Website Address"
                  name="WebsiteLink"
                  value={formData.WebsiteLink}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      WebsiteLink: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          )}
          {selectedSubmitType.value === 3 && (
            <>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Mail Address 1:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Address 1"
                    name="Address1"
                    value={formData.Address1}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        Address1: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Mail Address 2:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Address "
                    name="Address2"
                    value={formData.Address2}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        Address2: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>City:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter City "
                    name="City"
                    value={formData.City}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        City: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>State:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter State"
                    name="State"
                    value={formData.State}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        State: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Zip Code:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Zip Code"
                    name="Zip"
                    value={formData.Zip}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        Zip: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </>
          )}

          {selectedSubmitType !== "" && (
            <>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Att. to:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Department name"
                    name="AttnTo"
                    value={formData.AttnTo}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        AttnTo: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                {" "}
                <Form>
                  <Col md={12}>
                    <Form.Label>Requested Documents:</Form.Label>
                  </Col>
                  <Col>
                    {RequiredDocsJson.map((type) => (
                      <div className="mb-3" key={type.label}>
                        <Form.Check
                          name={type.label}
                          label={type.label}
                          checked={checkboxes[type.label]}
                          onChange={handleCheckboxChange}
                          type="checkbox"
                        />
                      </div>
                    ))}
                  </Col>
                </Form>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Appeal Limit:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="Enter Appeal Limit"
                    name="AppealLimit"
                    value={formData.AppealLimit}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        AppealLimit: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </>
          )}

          {subStatusList && subStatusList.length !== 0 && (
            <>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>
                    Sub Status <span className="text-danger">*</span>
                  </Form.Label>
                </Col>
                <Col>
                  <Select
                    onChange={handleSubStatusSelect}
                    options={subStatusList}
                    name="SubStatus"
                    value={selectedSubStatus}
                    isClearable
                  />
                </Col>
              </Row>
            </>
          )}

          {subStatusList && subStatusList.length !== 0 && (
            <>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Action:</Form.Label>
                </Col>
                <Col>
                  <Select
                    onChange={handleActionSelect}
                    options={filteredActionData}
                    name="ActionId"
                    value={selectedAction}
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Action Mode:</Form.Label>
                </Col>
                <Col>
                  <Select
                    onChange={handleActionModeSelect}
                    options={filteredActionModeData}
                    name="ActionModeId"
                    value={selectedActionMode}
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Follow Up Days:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="Enter Follow Up Days"
                    name="followUpDays"
                    value={formData.followUpDays}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        followUpDays: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Next FollowUp By:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Next FollowUp By"
                    name="followUpBy"
                    value={formData.followUpBy}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        followUpBy: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </>
          )}
          {/* Denial Status End  */}

          {/* Does DOS falls between effective and termination Date : yes  (End)*/}
        </Col>

        <Col md={12}>
          <Row className="mb-4">
            <Col md={12}>
              <Form.Label>Automated Notes:</Form.Label>
              {/* <div className="d-flex text-right">
              <Button variant="primary"  onClick={click}>Update</Button>
              </div> */}
            </Col>
            <Col>
              {/* <div
                className="disposition-sticky-note"
                ref={myRef}
                contentEditable={true}
                suppressContentEditableWarning={true}
              > */}
                {/* <span className="text-dark fw-semibold fs-12 lh-28">
                  {`Visit Number ${props?.taskDetails?.visitId} `}
                  {formData.status && `Claim Denied as `}{" "}
                  <b>{formData.statusName} </b>
                  {formData.dateOdDenial && " On"}{" "}
                  <b>{formData.dateOdDenial} </b>{" "}
                  {formData.claimId && "Claim # "}
                  <b>{formData.claimId} </b>
                  {formData.submitType === 1 &&
                    `Need to submit medical records By Fax `}
                  {formData.FAXNumber &&
                    `at Fax # ${formData.FAXNumber} ${
                      formData.AttnTo &&
                      ` Attn. to. ${formData.AttnTo} Send appeal with `
                    }`}
                  {formData.submitType === 2 &&
                    `Need to submit medical records by WEB  `}
                  {formData.WebsiteLink &&
                    `at  ${formData.WebsiteLink} ${
                      formData.AttnTo &&
                      ` Attn. to. ${formData.AttnTo} Send appeal with `
                    }`}
                  {formData.submitType === 3 &&
                    `Need to submit medical records by MAIL at  ${formData.Address1} ${formData.Address2} ${formData.City} ${formData.State} ${formData.Zip} `}
                  {formData.AttnTo && `Attn. to. ${formData.AttnTo} `}
                  {selectedSubmitType !== "" &&
                    Object.entries(checkboxes).map(
                      ([label, value]) =>
                        value && <span key={label}>{label}, </span>
                    )}
                  {formData.AppealLimit &&
                    ` Need to Appeal. Appeal limit is ${formData.AppealLimit} Days`}
                  {formData.ActionId === 1 && ". Claim Sent for MR"}
                </span> */}
                <div className="disposition-sticky-note" contentEditable={true} suppressContentEditableWarning={true}>
      <span className="text-dark fw-semibold fs-12 lh-28">
        {/* Concatenate dynamic content and user-editable content */}
        {stickyNoteContent}
        <span className="user-editable-content" contentEditable={true} suppressContentEditableWarning={true}></span>
      </span>
    </div>
              {/* </div> */}
            </Col>
          </Row>
        </Col>
      <div className="decision-tree-save-bttn">
        <Button variant="primary" onClick={handleSaveDisposition}>
          Save
        </Button>
      </div>
    </React.Fragment>
  );
}

export default MedicalRecordsDispose;
