import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import { Button, Badge, Form, Card } from "react-bootstrap";
import CreateNewGroup from "./create-new-group";
import UpdateNewGroup from "./update-group";
import DataTable from "react-data-table-component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ShimmerLoader from "../../_common/ShimmerLoader";

export default function UserGrups() {
  const axiosPrivate = useAxiosPrivate();
  const [showNewUser, setShowNewUser] = useState(false);
  const [userGroupList, setUserGroupList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const [showUdateUserGroup, setshowUdateUserGroup] = useState(false);
  const handleNewUser = (state) => {
    setShowNewUser(state);
  };

  useEffect(() => {
    getUserGroupList();
  }, []);

  const getUserGroupList = async () => {
    try {
      setLoading({ isLoading: true, value: "getUserGroupList" });
      const userGroupListData = await axiosPrivate.get(`Users/user-groups`);
      setUserGroupList(userGroupListData?.data?.result);
    } catch (err) {
      setLoading({ isLoading: false, value: "getUserGroupList" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "getUserGroupList" });
    }
  };
  const handleSearch = (event) => {
    setSearchText(event?.target?.value);
  };
  const filteredData = userGroupList.filter((item) => {
    return (
      item?.groupName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.userMappingCount?.toString().includes(searchText.toLowerCase())
    );
  });

  const DeleteUserGroups = async (UserGroupId, usercount) => {
    if (usercount > 0) {
      const result = await Swal.fire({
        title: "Are you sure?",
        text:
          "There are (" +
          usercount +
          ") users in this group. Do you really want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      });

      if (result.isConfirmed) {
        try {
          const userGroupListData = await axiosPrivate.post(
            `Users/delete-user-group/${UserGroupId}`
          );
          setUserGroupList(userGroupListData?.data?.result);
          Swal.fire("Deleted!", "The user group has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting user group:", error);
          Swal.fire(
            "Error",
            "There was a problem deleting the user group.",
            "error"
          );
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "The user group is safe :)", "error");
      }
    } else {
      try {
        const userGroupListData = await axiosPrivate.post(
          `Users/delete-user-group/${UserGroupId}`
        );
        setUserGroupList(userGroupListData?.data?.result);
      } catch (error) {
        console.error("Error deleting user group:", error);
        Swal.fire(
          "Error",
          "There was a problem deleting the user group.",
          "error"
        );
      }
    }
  };

  const columns = [
    {
      name: "Group Name",
      selector: (row) => row?.groupName,
      sortable: true,
      cell: (row) => <div>{row?.groupName}</div>,
    },
    {
      name: "Total Users",
      selector: (row) => row?.userMappingCount,
      sortable: true,
      cell: (row) => (
        <div>
          <Badge bg="primary" className="badge-pill">
            {row.userMappingCount}
          </Badge>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: true,
      cell: (row) => (
        <div className="w-1">
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="button"
              className="btn-icon me-2"
              onClick={() =>
                setshowUdateUserGroup({ showBool: true, UserRoleId: row?.id })
              }
            >
              <i className="ri-pencil-line"></i>
            </Button>

            <Button
              variant="danger"
              type="button"
              className="btn-icon"
              onClick={() => DeleteUserGroups(row?.id, row?.userMappingCount)}
            >
              <i className="ri-delete-bin-line"></i>
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <CreateNewGroup
        show={showNewUser}
        setUserGroupList={setUserGroupList}
        closeFunction={handleNewUser}
      />
      <UpdateNewGroup
        show={showUdateUserGroup?.showBool}
        setshowUdateUserGroup={setshowUdateUserGroup}
        UserRoleId={showUdateUserGroup?.UserRoleId}
        setUserGroupList={setUserGroupList}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-0">Task Groups</h4>
        </div>

        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-1"
            onClick={() => handleNewUser(true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add New Task</span>
          </Button>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          {loading.isLoading && loading.value === "getUserGroupList" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
