import React, { useState } from "react";
import "./system-setting.scss";
import Header from "../../layouts/Header";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, Tab, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import PriorityAllocation from "./PriorityAllocation/priority-allocation";
import SAAGMasterComponent from "./SaagMaster/Saag-master";
import UserGrups from "./UserGroups/user-groups";
import UserAccounts from "./UserAccounts/user-accounts";
import UserGroups from "./UserGroups/user-groups";
import Vendors from "./Vendor/vendor";

export default function SystemSettings() {
    const [isSidebarShow, setSidebarShow] = useState(false);

    return (
        <React.Fragment>
            <Header />
            <Tab.Container defaultActiveKey="priority_allocation_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs p-0">
                        <Card className="card-one">
                            <Card.Body>
                                {/* <h3 className="mb-3 fs-14 fw-bold text-dark">System Setting</h3> */}
                                <Nav variant="pills">
                                    <Nav.Item><Nav.Link eventKey="priority_allocation_tab"><i className="ri-alert-line"></i> Priority Mapping</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="saag_master_tab"><i className="ri-arrow-up-circle-line"></i> SAAG Master </Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="user_groups_tab"><i className="ri-group-line"></i> Task Group</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="user_account_tab"><i className="ri-user-2-line"></i> User Accounts</Nav.Link></Nav.Item>
                                    {/* <Nav.Item><Nav.Link eventKey="vendor_tab"><i className="ri-user-fill"></i> Vendors</Nav.Link></Nav.Item> */}
                                </Nav>
                            </Card.Body>
                        </Card>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>
                        <Tab.Content>
                            {/* PRIORITY ALLOCATION TAB */}
                            <Tab.Pane eventKey="priority_allocation_tab"><PriorityAllocation /></Tab.Pane>
                            {/* SAAG MASTER */}
                            <Tab.Pane eventKey="saag_master_tab"><SAAGMasterComponent /></Tab.Pane>
                            {/* USER ROLES TAB */}
                            <Tab.Pane eventKey="user_groups_tab"><UserGrups /></Tab.Pane>
                            {/* USER ACCOUNT TAB */}
                            <Tab.Pane eventKey="user_account_tab"><UserAccounts /></Tab.Pane>
                            {/* VENDOR TAB */}
                            {/* <Tab.Pane eventKey="vendor_tab"><Vendors /></Tab.Pane> */}
                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
}