import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Form, ButtonGroup, Button } from "react-bootstrap";
import "./case-disposition.scss";
import Select from "react-select";
import actionMode from "../../../../Json/DecisionTree/action-mode.json";
import useAuth from "../../../../hooks/useAuth";
import { axiosPrivate } from "../../../../api/axios";
import ToastifyService from "../../../_common/ToastifyService";
function DCoverage(props) {
  let myRef = useRef();
  const initialState = {
    statusName: "",
    dateOdDenial: "",
    claimId: "",
    policyEffectiveDate: "",
    policyTerminationeDate: "",
    doesDOSFall: "",
    IsRepProcessing: "",
    ETA: "",
    isOtherInsurance: "",
    InsuranceName: "",
    IsPolicyActive: "",
    ActionId: "",
    AppealLimit: "",
    AppealAddress: "",
    requiredDocuments: "",
    CallId: "",
    followUpBy:"",
    followUpDays:""
  };
  const [formData, setFormData] = useState(initialState);
  const { auth } = useAuth();
  const [selectedSubStatus, setSelectedSubStatus] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedActionMode, setSelectedActionMode] = useState("");
  const [subStatusList, setSubStatusList] = useState([]);
  // HANDLE ACTION FUNCTION
  const [filteredActionData, setFilteredActionData] = useState([]);
  // HANDLE ACTION MODE FUNCTION
  const [filteredActionModeData, setFilteredActionModeData] = useState([]);
  const [selectedToggle, setSelectedToggle] = useState("");
  const [effectiveYesToggle, setEffectiveYesToggle] = useState("");
  const [IsOtherIns, setIsOtherIns] = useState("");
  const [isPolicyActive, setIsPolicyActive] = useState("");
  const excludeCodes = [
    "ACSTTS",
    "INVSTTS",
    "TRSTTS",
    "BTYP",
    "WQTYP",
    "FBTYP",
    "DSTTS",
  ];
  const subStatusJson = [
    {
      value: 1,
      label: "DENIED INCORRECTLY._INSURANCE REPROCESSING",
    },
    {
      value: 2,
      label: "DENIED INCORRECTLY._INSURANCE DENIED REPROCESSING",
    },
    {
      value: 3,
      label: "OTHER INSURANCE ACTIVE FOR DOS",
    },
    {
      value: 4,
      label: "OTHER INSURANCE INACTIVE FOR DOS",
    },
    {
      value: 5,
      label: "NO OTHER INSURANCE IN SYSTEM",
    },
  ];

  const ActionJson = [
    {
      value: 1,
      label: "INSURANCE_REPROCESSING",
    },
    {
      value: 2,
      label: "Appeal Sent",
    },
    {
      value: 3,
      label: "REBILLED",
    },
    {
      value: 4,
      label: "BILL PATIENT",
    },
    {
      value: 5,
      label: "BILL PATIENT",
    },
  ];
   const ActionModeJson = [
    {
      value: 1,
      label: "PAPER",
      followUpdays:35,
      followUPBy:'AR'
    },
    {
      value: 2,
      label: "TASK",
      followUpdays:3,
      followUPBy:'BILLING'
    },
    {
      value: 3,
      label: "ELECTRONIC",
      followUpdays:22,
      followUPBy:'AR'
    },
    {
      value: 4,
      label: "DMA",
      followUpdays:15,
      followUPBy:'BILLING'
    },
    {
      value: 5,
      label: "SYSTEM",
      followUpdays:35,
      followUPBy:'AR'
    },
    {
      value: 6,
      label: "CALL",
      followUpdays:22,
      followUPBy:'AR'
    },
  ];
  useEffect(() => {
    // // OPTION ACTION MODE DATA
    setFilteredActionModeData(ActionModeJson);
  }, []);

  const handleActionSelect = (event) => {
    
    setFormData({
      ...formData,
      ActionId: event.value,
      ActionName: event.label,
      ActionModeId: "",
    });
    setSelectedAction(event);
  };

  useEffect(() => {
    // setSelectedToggle("");
    setSubStatusList([]);
    setFormData({
      ...formData,
      status: props.selectedStatus.value,
      statusName: props.selectedStatus.label,
    });
  }, [props.selectedStatus]);
  // STATUS FUNCTION

  // SUB STATUS FUNCTION
  const handleSubStatusSelect = (event) => {
    setFormData({
      ...formData,
      SubStatus: event.value,
      subStatusName: event.label,
    });
  
    setSelectedAction("");
    setSelectedSubStatus(event);
    if (formData.status !== props?.selectedStatus?.value) {
      const actionsList = JSON.parse(sessionStorage.getItem("commonField"))
        ?.find((wqty) => wqty.id === formData.status)
        ?.fieldValues?.find((action) => action.id === event.value)
        ?.commonFieldActions?.map((item) => ({
          value: item.id,
          label: item.actionName,
        }));
      setFilteredActionData(actionsList);
    } else {
      const actionsList = ActionJson.filter(
        (stts) => stts.value === event.value
      ).map((item) => ({
        value: item.value,
        label: item.label,
      }));
      setFilteredActionData(actionsList);
    }
  };

  const handleActionModeSelect = (event) => {
    setSelectedActionMode(event);
    setFormData({
      ...formData,
      ActionModeId: event.value,
      ActionModeName: event.label,
      followUpBy:event.followUPBy , 
      followUpDays:event.followUpdays
    });
   
  };

  const handleToggle = (value) => {
    setSelectedToggle(value);
    setSubStatusList([]);
    setEffectiveYesToggle("");
    setIsPolicyActive("");
    setIsOtherIns("");
    setFormData({
      ...formData,
      doesDOSFall: value,
      IsRepProcessing: "",
      isOtherInsurance: "",
      AppealLimit: "",
      AppealAddress: "",
      ETA: "",
      InsuranceName: "",
      IsPolicyActive: "",
      requiredDocuments: "",
      CallId: "",
      ActionModeId: "",
      ActionId: "",
      followUpBy:"",
    followUpDays:""
    });
  };
  const handleEffectiveYesToggle = (value) => {
    setSelectedSubStatus("");
    setSelectedAction("");
    setSubStatusList([]);
    setEffectiveYesToggle(value);
    setFormData({
      ...formData,
      IsRepProcessing: value,
      isOtherInsurance: "",
      AppealLimit: "",
      AppealAddress: "",
      ETA: "",
      InsuranceName: "",
      IsPolicyActive: "",
      requiredDocuments: "",
      CallId: "",
      ActionId: "",
      ActionModeId: "",
      followUpBy:"",
    followUpDays:""
    });
    const data = value === "Agree" ? 1 : 2;
    // if (value === "Agree") {
    const subStatusList = subStatusJson
      ?.filter((stts) => stts.value === data)
      .map((item) => ({
        value: item.value,
        label: item.label,
      }));
    setSubStatusList(subStatusList);
  };

  const handleInsuranceToggle = (value) => {
    setSelectedSubStatus("");
    setSelectedAction("");
    setSubStatusList([]);
    setIsPolicyActive("");
    setFormData({
      ...formData,
      isOtherInsurance: value,
      AppealLimit: "",
      AppealAddress: "",
      ETA: "",
      InsuranceName: "",
      IsPolicyActive: "",
      requiredDocuments: "",
      CallId: "",
      ActionId: "",
      ActionModeId: "",
      followUpBy:"",
    followUpDays:""
    });
    setIsOtherIns(value);
    const data = value === "no" ? 5 : "";
    const subStatusList = subStatusJson
      .filter((stts) => stts.value === data)
      .map((item) => ({
        value: item.value,
        label: item.label,
      }));
    setSubStatusList(subStatusList);
  };

  const handleIsPolicyActiveToggle = (value) => {
    setSelectedSubStatus("");
    setSelectedAction("");
    setSubStatusList([]);
    setIsPolicyActive(value);

    setFormData({
      ...formData,
      IsPolicyActive: value,
      AppealLimit: "",
      AppealAddress: "",
      ETA: "",
      requiredDocuments: "",
      CallId: "",
      ActionId: "",
      ActionModeId: "",
      followUpBy:"",
    followUpDays:""
      
    });
    const data = value === "yes" ? 3 : 4;
    const subStatusList = subStatusJson
      .filter((stts) => stts.value === data)
      .map((item) => ({
        value: item.value,
        label: item.label,
      }));
    setSubStatusList(subStatusList);
  };
  const handleSaveDisposition = async () => {
    const formObj = {
      caseId: props?.taskDetails?.id,
      visitId: props?.taskDetails?.visitId,
      dispositionBody: JSON.stringify(formData),
      dispositionRemark: stickyNoteContent,
      modifiedBy: auth.id,
    };
    console.log(formObj);
    try {
      const response = await axiosPrivate.post(
        "Task/case-disposition",
        formObj
      );
      props?.setPendingTasks(response.data.result.pendingTasks)
      props?.setTaskSummary(
        response?.data?.result?.taskBucketSummary?.taskBucketDetails
      );
      props?.setTeamLeadQueries(response?.data?.result?.tlQueries);
      props?.setParkedTasks(response?.data?.result?.parkedTasks);
      props?.setMyTask(response?.data?.result);
      props.setShowTasksDetails({
        showTaskDetails: false,
        taskId:"",
      })
      ToastifyService.success("Case Disposed SuccessFully");
    } catch (error) {
      console.error(error);
    }
  };
  const [stickyNoteContent, setStickyNoteContent] = useState('');

  // Function to generate the content of the disposition sticky note
  const generateStickyNoteContent = () => {
    let content = `Visit Number ${props?.taskDetails?.visitId} `;
    if (formData.status) {
      content += `Claim Denied as ${formData.statusName}. `;
    }
    if (formData.dateOdDenial) {
      content += `On ${formData.dateOdDenial}. `;
    }
    if (formData.claimId) {
      content += `Claim # ${formData.claimId}. `;
    }
    if (formData.policyEffectiveDate) {
      content += `As per payer policy is effective from ${formData.policyEffectiveDate}. `;
    }
    if (formData.policyTerminationeDate) {
      content += `to ${formData.policyTerminationeDate}. `;
    }
    if (formData.doesDOSFall === "yes") {
      content += "Claim is denied incorrectly. The DOS falls between the effective date of policy. ";
    }
    if (formData.doesDOSFall === "no") {
      content += "Claim is denied correctly. The DOS falls outside of effective date of policy. ";
    }
    if (formData.IsRepProcessing === "Agree") {
      content += "Rep agreed to reprocess the claim. ";
      if (formData.ETA) {
        content += `It can take upto ${formData.ETA} Days to reprocess. `;
      }
    }
    if (formData.IsRepProcessing === "Disagree") {
      content += "Rep denied to reprocess the claim. ";
      if (formData.AppealLimit) {
        content += `Need to Appeal. Appeal limit is ${formData.AppealLimit} Days. `;
      }
      if (formData.AppealAddress) {
        content += `from ${formData.AppealAddress}. `;
      }
      if (formData.requiredDocuments) {
        content += `Send appeal with ${formData.requiredDocuments}. `;
      }
    }
    if (formData.isOtherInsurance === "yes") {
      content += "Checked System. Found other insurance ";
      if (formData.InsuranceName) {
        content += `as ${formData.InsuranceName}. `;
      }
      if (formData.IsPolicyActive === "yes") {
        content += "Checked eligibility found policy active for DOS. ";
      } else if (formData.IsPolicyActive === "no") {
        content += "Checked eligibility found policy inactive for DOS. ";
      }
    }
    if (formData.isOtherInsurance === "no") {
      content += "Checked System. No other insurance found. ";
    }
    if (formData.CallId) {
      content += `Call Referrence# ${formData.CallId}. `;
    }
    if (formData.ActionId === 1) {
      content += "Claim Sent for Reprocessing. ";
    } else if (formData.ActionId === 2) {
      content += "Appeal sent to payer. ";
    } else if (formData.ActionId === 3) {
      content += "Claim billed to active payer. ";
    } else if (formData.ActionId === 4 || formData.ActionId === 5) {
      content += "Claim billed to Patient. ";
    }

    // Update the state with the generated content
    setStickyNoteContent(content);
  };

  // Call the function to generate the initial content when component mounts
  useEffect(() => {
    generateStickyNoteContent();
  }, [props.taskDetails, formData]);
  return (
    <React.Fragment>
        <Col md={12}>
          {/* Denial Status Start */}

          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Date Of Denial:</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="date"
                name="dateOdDenial"
                value={formData.dateOdDenial}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    dateOdDenial: e.target.value,
                  })
                }
              ></Form.Control>
            </Col>
          </Row>
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Claim #:</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                placeholder="Enter Claim #"
                name="claimId"
                value={formData.claimId}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    claimId: e.target.value,
                  })
                }
              ></Form.Control>
            </Col>
          </Row>
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Policy Effective Date:</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="date"
                name="policyEffectiveDate"
                value={formData.policyEffectiveDate}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    policyEffectiveDate: e.target.value,
                  })
                }
              ></Form.Control>
            </Col>
          </Row>

          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Policy Termination Date:</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="date"
                name="policyTerminationeDate"
                value={formData.policyTerminationeDate}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    policyTerminationeDate: e.target.value,
                  })
                }
              ></Form.Control>
            </Col>
          </Row>

          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>
                Does DOS falls between effective and termination Date?
              </Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    selectedToggle === "yes" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("yes")}
                >
                  <input
                    type="radio"
                    name="doesDOSFall"
                    className="btn-check"
                    autoComplete="off"
                    checked={selectedToggle === "yes"}
                    onChange={() => handleToggle("yes")}
                  />
                  Yes
                </Button>
                <Button
                  variant={
                    selectedToggle === "no" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("no")}
                >
                  <input
                    type="radio"
                    name="doesDOSFall"
                    className="btn-check"
                    autoComplete="off"
                    checked={selectedToggle === "no"}
                    onChange={() => handleToggle("no")}
                  />
                  No
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          {/* Does DOS falls between effective and termination Date : No (start)*/}
          {selectedToggle === "no" && (
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Other Insurance in System</Form.Label>
              </Col>
              <Col>
                <ButtonGroup>
                  <Button
                    variant={IsOtherIns === "yes" ? "primary" : "outline-primary"}
                    onClick={() => handleInsuranceToggle("yes")}
                  >
                    <input
                      type="radio"
                      name="options"
                      className="btn-check"
                      autoComplete="off"
                      checked={IsOtherIns === "yes"}
                      onChange={() => handleInsuranceToggle("yes")}
                    />
                    Yes
                  </Button>
                  <Button
                    variant={IsOtherIns === "no" ? "primary" : "outline-primary"}
                    onClick={() => handleInsuranceToggle("no")}
                  >
                    <input
                      type="radio"
                      name="options"
                      className="btn-check"
                      autoComplete="off"
                      checked={IsOtherIns === "no"}
                      onChange={() => handleInsuranceToggle("no")}
                    />
                    No
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          )}
          {selectedToggle === "no" && IsOtherIns === "yes" && (
            <>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Insurance Name</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Insurance Name"
                    name="InsuranceName"
                    value={formData.InsuranceName}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        InsuranceName: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Is Policy Active for DOS</Form.Label>
                </Col>
                <Col>
                  <ButtonGroup>
                    <Button
                      variant={
                        isPolicyActive === "yes" ? "primary" : "outline-primary"
                      }
                      onClick={() => handleIsPolicyActiveToggle("yes")}
                    >
                      <input
                        type="radio"
                        name="options"
                        className="btn-check"
                        autoComplete="off"
                        checked={isPolicyActive === "yes"}
                        onChange={() => handleIsPolicyActiveToggle("yes")}
                      />
                      Yes
                    </Button>
                    <Button
                      variant={
                        isPolicyActive === "no" ? "primary" : "outline-primary"
                      }
                      onClick={() => handleIsPolicyActiveToggle("no")}
                    >
                      <input
                        type="radio"
                        name="options"
                        className="btn-check"
                        autoComplete="off"
                        checked={isPolicyActive === "no"}
                        onChange={() => handleIsPolicyActiveToggle("no")}
                      />
                      No
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </>
          )}
          {/* Does DOS falls between effective and termination Date : No (End)*/}

          {/* Does DOS falls between effective and termination Date : yes  (start)*/}
          {selectedToggle === "yes" && (
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Ask rep to reprocess the claim:</Form.Label>
              </Col>
              <Col>
                <ButtonGroup>
                  <Button
                    variant={
                      effectiveYesToggle === "Agree"
                        ? "primary"
                        : "outline-primary"
                    }
                    onClick={() => handleEffectiveYesToggle("Agree")}
                  >
                    <input
                      type="radio"
                      name="options"
                      className="btn-check"
                      autoComplete="off"
                      checked={effectiveYesToggle === "Agree"}
                      onChange={() => handleEffectiveYesToggle("Agree")}
                    />
                    Agree
                  </Button>
                  <Button
                    variant={
                      effectiveYesToggle === "Disagree"
                        ? "primary"
                        : "outline-primary"
                    }
                    onClick={() => handleEffectiveYesToggle("Disagree")}
                  >
                    <input
                      type="radio"
                      name="options"
                      className="btn-check"
                      autoComplete="off"
                      checked={effectiveYesToggle === "Disagree"}
                      onChange={() => handleEffectiveYesToggle("Disagree")}
                    />
                    Disagree
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          )}

          {subStatusList && subStatusList.length !== 0 && (
            <>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>
                    Sub Status <span className="text-danger">*</span>
                  </Form.Label>
                </Col>
                <Col>
                  <Select
                    onChange={handleSubStatusSelect}
                    options={subStatusList}
                    name="SubStatus"
                    value={selectedSubStatus}
                    isClearable
                  />
                </Col>
              </Row>
            </>
          )}

          {/* Agree REPROCESSING start */}
          {effectiveYesToggle === "Agree" && selectedToggle === "yes" && (
            <>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>ETA for reprocess:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="ETA for reprocess"
                    name="ETA"
                    value={formData.ETA}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ETA: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Call Referrence #:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Call Referrence #"
                    name="CallId"
                    value={formData.CallId}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        CallId: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </>
          )}
          {/* Agre REPROCESSING end  */}

          {/* Disagree REPROCESSING start */}
          {effectiveYesToggle === "Disagree" && selectedToggle === "yes" && (
            <>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Appeal Limit:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="Enter Appeal Limit"
                    name="AppealLimit"
                    value={formData.AppealLimit}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        AppealLimit: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-4 ">
                <Col md={12}>
                  <Form.Label>Appeals Address:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder="Enter Appeals Address"
                    name="AppealAddress"
                    value={formData.AppealAddress}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        AppealAddress: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Required Documents:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="requiredDocuments"
                    value={formData.requiredDocuments}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        requiredDocuments: e.target.value,
                      })
                    }
                    placeholder="Enter Required Documents"
                  />
                </Col>
              </Row>

              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Call Referrence #:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Call Referrence #"
                    name="CallId"
                    value={formData.CallId}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        CallId: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </>
          )}
          {/* Disagree REPROCESSING end */}

          {subStatusList && subStatusList.length !== 0 && (
            <>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Action:</Form.Label>
                </Col>
                <Col>
                  <Select
                    onChange={handleActionSelect}
                    options={filteredActionData}
                    name="ActionId"
                    value={selectedAction}
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Action Mode:</Form.Label>
                </Col>
                <Col>
                  <Select
                    onChange={handleActionModeSelect}
                    options={filteredActionModeData}
                    name="ActionModeId"
                    value={selectedActionMode}
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Follow Up Days:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="Enter Follow Up Days"
                    name="followUpDays"
                    value={formData.followUpDays}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        followUpDays: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Next FollowUp By:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Next FollowUp By"
                    name="followUpBy"
                    value={formData.followUpBy}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        followUpBy: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </>
          )}
          {/* Denial Status End  */}

          {/* Does DOS falls between effective and termination Date : yes  (End)*/}
        </Col>

        <Col md={12}>
          <Row className="mb-4">
            <Col md={12}>
              <Form.Label>Automated Notes:</Form.Label>
            </Col>
            <Col>
              {/* <div className="disposition-sticky-note" >
                <span className="text-dark fw-semibold fs-12 lh-28">{stickyNoteContent} */}
                  {/* {`Visit Number ${props?.taskDetails?.visitId} ` }
                  {formData.status && `Claim Denied as `}{" "}
                  <b>{formData.statusName} </b>
                  {formData.dateOdDenial && " On"} <b>{formData.dateOdDenial} </b>{" "}
                  {formData.claimId && "Claim # "}
                  <b>{formData.claimId} </b>
                  {formData.policyEffectiveDate &&
                    ". As per payer policy is effective from"}{" "}
                  <b>{formData.policyEffectiveDate} </b>
                  {formData.policyTerminationeDate && " to "}
                  <b>{formData.policyTerminationeDate} </b>
                  {formData.doesDOSFall === "yes" &&
                    " Claim is denied incorrectly. The DOS falls between the effective date of policy"}
                  {formData.doesDOSFall === "no" &&
                    " Claim is denied correctly. The DOS falls outside of effective date of policy."}{formData.doesDOSFall === "yes" &&
                    formData.IsRepProcessing === "Agree" &&
                    ". Rep agreed to reprocess the claim"}
                  {formData.doesDOSFall === "yes" &&
                    formData.IsRepProcessing === "Disagree" &&
                    ". Rep denied to reprocess the claim"}
                  {formData.IsRepProcessing === "Agree" &&
                    formData.ETA &&
                    `. It can take upto ${formData.ETA} Days to reprocess`}
                  {formData.doesDOSFall === "no" &&
                    formData.isOtherInsurance === "yes" &&
                    "  Checked System. Found other insurance "}
                  {formData.doesDOSFall === "no" &&
                    formData.isOtherInsurance === "no" &&
                    "  Checked System. No other insurance found."}
                  {formData.isOtherInsurance === "yes" &&
                    formData.InsuranceName &&
                    `as ${formData.InsuranceName} `}
                  {formData.isOtherInsurance === "yes" &&
                    formData.IsPolicyActive === "yes" &&
                    ` Checked eligibility found policy active for DOS `}
                  {formData.isOtherInsurance === "yes" &&
                    formData.IsPolicyActive === "no" &&
                    ` Checked eligibility found policy inactive for DOS `}
                 {formData.IsRepProcessing === "Disagree" &&
                    formData.AppealLimit &&
                    ` Need to Appeal. Appeal limit is ${formData.AppealLimit} Days`}
                  {formData.IsRepProcessing === "Disagree" &&
                    formData.AppealAddress &&
                    ` from ${formData.AppealAddress}`}
                  {formData.IsRepProcessing === "Disagree" &&
                    formData.requiredDocuments &&
                    ` Send appeal with  ${formData.requiredDocuments}`}
                  {formData.CallId && ". Call Referrence#"}{" "}
                  <b>{formData.CallId}</b>
                  {formData.ActionId === 1 && ". Claim Sent for Reprocessing"}
                  {formData.ActionId === 2 && ". Appeal sent to payer"}
                  {formData.ActionId === 3 && ". Claim billed to active payer"}
                  {formData.ActionId === 4 && ".  Claim billed to Patient"}
                  {formData.ActionId === 5 && ".  Claim billed to Patient"} */}
                {/* </span>
      <span className="user-editable-content" contentEditable={true} suppressContentEditableWarning={true}></span> */}
     <div className="disposition-sticky-note" contentEditable={true} suppressContentEditableWarning={true}>
      <span className="text-dark fw-semibold fs-12 lh-28">
        {/* Concatenate dynamic content and user-editable content */}
        {stickyNoteContent}
        <span className="user-editable-content" contentEditable={true} suppressContentEditableWarning={true}></span>
      </span>
    </div>
            </Col>
          </Row>
        </Col>
      <div className="decision-tree-save-bttn">
        <Button variant="primary" onClick={handleSaveDisposition}>
          Save
        </Button>
      </div>
    </React.Fragment>
  );
}

export default DCoverage;
