import React, { useEffect, useRef, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Image } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  dahsboardMenu,
  reportsMenu,
  MasterCatalogue,
  TaskEngineMenu,
  ProductionMenu,
  SystemSettings,
} from "../data/Menu";
import logo from "../assets/img/logo/main-logo.png";
import useAuth from "../hooks/useAuth";
import { TaskContext } from "./TaskContext";

const Sidebar = () => {
  const { setAuth, auth } = useAuth();
  const scrollBarRef = useRef(null);
  const toggleFooterMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest(".sidebar");
    parent.classList.toggle("footer-menu-show");
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      const sidebar = document.querySelector(".sidebar");
      if (!sidebar.contains(e.target) && !e.target.closest(".menu-link")) {
        document.querySelector("body").classList.remove("sidebar-show");
      }
      if (!e.target.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
      }
    };

    const handleLoad = () => {
      let skinMode = localStorage.getItem("sidebar-skin");
      let HTMLTag = document.querySelector("html");
      if (skinMode) {
        HTMLTag.setAttribute("data-sidebar", skinMode);
      }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <Link to="/" className="sidebar-logo">
          <Image src={logo} className="wt-150 dark-mode-logo" />
        </Link>
      </div>
      <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
        <SidebarMenu onUpdateSize={() => scrollBarRef.current.updateScroll()} />
      </PerfectScrollbar>
      <div className="sidebar-footer">
        <div className="sidebar-footer-top">
          <div className="sidebar-footer-thumb">
            <div className="user-profile">
              <i className="ri-user-fill fs-20"></i>
            </div>
          </div>
          <div className="sidebar-footer-body">
            <h6>{auth?.name}</h6>
            <p>{auth?.roles[0]}</p>
          </div>
          <Link
            onClick={toggleFooterMenu}
            to=""
            className="dropdown-link ht-40"
          >
            <i className="ri-arrow-down-s-line"></i>
          </Link>
        </div>
        <div className="sidebar-footer-menu">
          <nav className="nav">
            <Link
              to="/account/login"
              onClick={() => {
                sessionStorage.clear();
                setAuth({});
                window.location.reload();
              }}
            >
              <i className="ri-logout-box-r-line"></i> Log Out
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

const SidebarMenu = ({ onUpdateSize }) => {
  const toggleMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");
    onUpdateSize();
  };

  const toggleSubMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;
    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }
    parent.classList.toggle("show");
    onUpdateSize();
  };

  const populateMenu = (m) => {
    return (
      <ul className="nav nav-sidebar">
        {m.map((item, key) => {
          let subMenu;
          if (item.submenu) {
            subMenu = item.submenu.map((subItem, subKey) => (
              <NavLink
                to={subItem.link}
                className="nav-sub-link position-relative"
                key={subKey}
              >
                {subItem.label}{" "}
                <span className="badge bg-primary badge-pill position-absolute right-10">
                  {subItem.count}
                </span>
              </NavLink>
            ));
          }
          return (
            <li key={key} className="nav-item">
              {!subMenu ? (
                <NavLink to={item.link} className="nav-link position-relative">
                  <i className={item.icon}></i> <span>{item.label}</span>{" "}
                  <span className="badge bg-primary badge-pill position-absolute right-10">
                    {item.count}
                  </span>
                </NavLink>
              ) : (
                <div
                  onClick={toggleSubMenu}
                  className="nav-link has-sub position-relative"
                >
                  <i className={item.icon}></i> <span>{item.label}</span>{" "}
                  <span className="badge bg-primary badge-pill position-absolute right-10">
                    {item.count}
                  </span>
                </div>
              )}
              {item.submenu && <nav className="nav nav-sub">{subMenu}</nav>}
            </li>
          );
        })}
      </ul>
    );
  };
  const { pendingTasksLength, pendingQueriesLength } = useContext(TaskContext);
  const myTaskMenu = [
    {
      label: "My Tasks",
      link: "/MyTask/manage",
      icon: "ri-task-line",
      count: pendingTasksLength,
    },
  ];
  const TaskMenu = [
    {
      label: "Pending Queries",
      link: "/pendingQueries/manage",
      icon: "ri-stack-line",
      count: pendingQueriesLength,
    },
  ];
  const { setAuth, auth } = useAuth();
  return (
    <React.Fragment>
      {auth?.roles[0] === "Employee" ? (
        <div className="nav-group show without-dropdown">
          {populateMenu(myTaskMenu)}
        </div>
      ) : (
        <React.Fragment>
          <div className="nav-group show without-dropdown">
            {populateMenu(dahsboardMenu)}
          </div>
          <div className="nav-group show without-dropdown">
            {populateMenu(TaskMenu)}
          </div>
          <div className="nav-group">
            <div
              className="nav-label text-dark fs-14 semibold"
              onClick={toggleMenu}
            >
              Reports
            </div>
            {populateMenu(reportsMenu)}
          </div>
          <h5
            className="fw-bold text-dark fs-14 mt-3 mb-3 ml-1"
            style={{ marginLeft: "20px" }}
          >
            Manage
          </h5>
          <div className="nav-group show without-dropdown">
            {populateMenu(MasterCatalogue)}
          </div>
          <div className="nav-group show without-dropdown">
            {populateMenu(TaskEngineMenu)}
          </div>
          <div className="nav-group show without-dropdown">
            {populateMenu(ProductionMenu)}
          </div>
          <div className="nav-group show without-dropdown">
            {populateMenu(SystemSettings)}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
