import React, { useEffect, useState } from "react";
import {
  Button,
  Offcanvas,
  Form,
  Row,
  Col,
  Tabs,
  Tab,
  Table,
} from "react-bootstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ToastifyService from "../../_common/ToastifyService";
import Commonfield from "../../../infrastructure/core/Commonfield";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function UpdateNewGroup(props) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [formData, setFormData] = useState({});
  const [MappedList, setMappedUserList] = useState([]);
  const [userAccountList, setUserAccountList] = useState([]);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  useEffect(() => {
    if (props?.UserRoleId) {
      getPriorityMappingDetails(props?.UserRoleId);
      getUserAccountList();
    }
  }, [props?.UserRoleId]);

  const getUserGroupList = async () => {
    try {
      const userGroupListData = await axiosPrivate.get(`Users/user-groups`);
      props?.setUserGroupList(userGroupListData?.data?.result);
    } catch (err) {
      console.error(err);
    }
  };
  const getUserAccountList = async () => {
    try {
      const userAccountListData = await axiosPrivate.get(`Users/users`);
      setUserAccountList(userAccountListData?.data?.result);
    } catch (err) {
      // console.error(err);
    }
  };

  const getPriorityMappingDetails = async (UserRoleId) => {
    try {
      const userRoleData = await axiosPrivate.get(
        `Users/user-group-details/${UserRoleId}`
      );
      setFormData(userRoleData?.data?.result?.groupDetails);
      setMappedUserList(userRoleData?.data?.result?.users);
    } catch (err) {
      // console.error(err);
    }
  };
  const handleSubmit = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "UpdateTask",
      });
      const userRoleListData = await axiosPrivate.post(
        `Users/update-user-group`,
        {
          ...formData,
          status: Commonfield.status.active,
          modifiedBy: auth?.id,
        }
      );
      props?.setUserGroupList(userRoleListData?.data?.result);
      ToastifyService.success("User Group Updated Successfully");
    } catch (err) {
      ToastifyService.error("Oops! Something Went Wrong");
      setLoading({
        isLoading: false,
        value: "UpdateTask",
      });
    }finally{
      setLoading({
        isLoading: false,
        value: "UpdateTask",
      });
    }
  };
  const StatusChange = async (userId) => {
    try {
      const userStatusListData = await axiosPrivate.post(
        `Users/assign-task-group`,
        {
          ...formData,
          taskGroupId: formData?.id,
          userId: userId,
          createdBy: auth?.id,
        }
      );
      setMappedUserList(userStatusListData?.data?.result?.users);
      getUserGroupList();
      ToastifyService.success("User Included Successfully");
    } catch (err) {
      ToastifyService.error("Oops! Something Went Wrong");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={() => props?.setshowUdateUserGroup({ showBool: false, UserRoleId: "" })} placement="end" className="w-40">
        <Offcanvas.Header>
          <Offcanvas.Title className="fs-16 text-dark">Update Task Group</Offcanvas.Title>
          <button type="button" class="btn-close" aria-label="Close" onClick={() => props?.setshowUdateUserGroup({ showBool: false, UserRoleId: "" })}></button>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Tabs defaultActiveKey="edit_form_tab" className="common-nav-tabs">
            <Tab
              eventKey="edit_form_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Edit Detail</h6>
                </>
              }
            >
              <div className="custom-tab-body mt-3">
                <Row>
                  <Col xl={8}>
                    <div className="mb-4">
                      <Form.Label>Group Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Group Name"
                        value={formData?.groupName}
                        name="groupName"
                        onChange={handleChange}
                      />
                    </div>
                  </Col>

                  <Col xl={8}>
                    <div className="mb-4">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter Description"
                        value={formData?.groupDescription}
                        name="groupDescription"
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

           
              <Button
                type="button"
                variant="primary"
                className="fs-14 me-2 d-flex align-items-center"
                disabled={loading.isLoading && loading?.value === "UpdateTask"}
                onClick={handleSubmit}
              > {loading.isLoading && loading?.value === "UpdateTask" ? (
                <LoadingSpinner
                  color="#ffffff"
                  size={20}
                  type={"TailSpin"}
                />
              ) : (
                <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Update</span>
                </>
                 
                )}
          
              </Button>
            </Tab>

            <Tab
              eventKey="group_list_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Mapped Users</h6>
                </>
              }
            >
              <div className="custom-tab-body mt-3">
                <Table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>User Full Name</th>
                    </tr>
                  </thead>

                  <tbody>
                    {userAccountList.map((userList, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Check
                            type="switch"
                            name="userId"
                            className="me-3 fs-16 fw-semibold"
                            checked={MappedList?.some(
                              (mappedItem) => mappedItem?.id === userList?.id
                            )}
                            onChange={() => StatusChange(userList?.id)}
                          />
                        </td>
                        <td>
                          <b>{userList?.fullName}</b>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
