import React, { useEffect, useState } from "react";
import { Form, Badge, Card, Button, Dropdown } from "react-bootstrap";
import ManuallyAssignComponent from "./manually-assign";
import DataTable from "react-data-table-component";
import ShimmerLoader from "../../_common/ShimmerLoader";

export default function PendingAssignment(props) {
  const [showManuallyAssign, setShowManuallyAssign] = useState(false);
  const [PendingInventoryList, setpendingInventory] = useState([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    setpendingInventory(props?.PendingInventoryList);
  }, [props?.PendingInventoryList]);

  const ManuallyAssign = (state) => {
    setShowManuallyAssign(state);
  };
  const [selectedTask, setSelectedTask] = useState([]);

  const handleCheckboxChange = (row) => {
    setSelectedTask((prevSelected) => {
      if (prevSelected.some((task) => task.caseId === row.id)) {
        // If the row ID is already in the array, remove it
        return prevSelected.filter((task) => task.caseId !== row.id);
      } else {
        // If the row ID is not in the array, add it
        return [...prevSelected, { visitID: row.visitID, caseId: row.id }];
      }
    });
  };

  const columns = [
    {
      name: "Visit ID",
      selector: (row) => row.visitID,
      sortable: true,
      cell: (row) => (
        <div className="d-flex justify-content-start">
          <div className="me-2">
            <Form.Check
              type="checkbox"
              checked={selectedTask.some((task) => task.caseId === row.id)}
              onChange={() => handleCheckboxChange(row)}
            />
          </div>
          <div>{row.visitID}</div>
        </div>
      ),
    },
    {
      name: "Ref. ID",
      selector: (row) => row.referenceID,
      sortable: true,
      cell: (row) => <div>{row.referenceID}</div>,
    },
    {
      name: "Patient Name",
      selector: (row) => row.patientName,
      sortable: true,
      cell: (row) => (
        <div>
          <h5 className="fs-14 mb-1 fw-bold">{row.patientName}</h5>
        </div>
      ),
    },
    {
      name: "Priority",
      selector: (row) => row.taskPriority,
      sortable: true,
      cell: (row) => (
        <div>
          <b>{row.taskPriority}</b>
        </div>
      ),
    },
    {
      name: "Task Bucket",
      selector: (row) => row.taskBucketName,
      sortable: true,
      cell: (row) => (
        <div>
          <Badge className="bg-secondary">{row.taskBucketName}</Badge>
        </div>
      ),
    },
    {
      name: "Insurance Balance",
      selector: (row) => row.insuranceBalance,
      sortable: true,
      cell: (row) => (
        <div>
          <b>$ {row.insuranceBalance}</b>
        </div>
      ),
    },
    {
      name: "Last bill date",
      selector: (row) =>
        row.lastBillDate === "0001-01-01T00:00:00"
          ? "-"
          : new Date(row?.lastBillDate).toLocaleString(),
      sortable: true,
      cell: (row) => (
        <div>
          {row.lastBillDate === "0001-01-01T00:00:00"
            ? "-"
            : new Date(row?.lastBillDate).toLocaleString()}
        </div>
      ),
    },
  ];

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const filteredData = PendingInventoryList?.filter((item) => {
    return (
      item?.visitID?.toString().includes(searchText.toLowerCase()) ||
      item?.referenceID?.toString().includes(searchText.toLowerCase()) ||
      item?.patientName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.taskPriority?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.taskBucketName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.insuranceBalance?.toString().includes(searchText.toLowerCase()) ||
      new Date(item?.lastBillDate)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
  });
  // setpendingInventory(filteredData)
  return (
    <React.Fragment>
      <ManuallyAssignComponent
        show={showManuallyAssign}
        closeFunction={ManuallyAssign}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        setpendingInventory={setpendingInventory}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-0">Unassigned Cases</h4>
        </div>

        <div className="d-flex align-items-center gap-3">
          <Dropdown className="me-2">
            <Dropdown.Toggle
              variant="primary"
              className="custom-dropdown-action ht-30 pe-2 ps-2 pt-0 pb-0"
              id="dropdown-basic"
            >
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => ManuallyAssign(true)}>
                Manually Assign
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          {props?.loading?.isLoading &&
          props?.loading?.value === "getPendingInventory" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
