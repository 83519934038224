import React, { useEffect, useState } from "react";
import { Button, Form, Card, Table } from "react-bootstrap";
import AddPriorityMapping from "./create-priority-modal";
import UpdatePriority from "./priority-modal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Link } from "react-router-dom";
import ShimmerLoader from "../../_common/ShimmerLoader";

export default function PriorityAllocation() {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const [showAddPriority, setShowAddPriority] = useState(false);
  const [showUpdatePriority, setShowUpdatePriority] = useState({
    showBool: false,
    priorityId: "",
  });
  const [priorityMappingList, setPriorityMappingList] = useState([]);
  const [searchingList, setSearchist] = useState([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    getPriorityMappingLList();
  }, []);

  const getPriorityMappingLList = async () => {
    try {
      setLoading({ isLoading: true, value: "getPriorityMappingLList" });
      const priorityMappingListData = await axiosPrivate.get(
        `Setting/task-priority`
      );
      setPriorityMappingList(priorityMappingListData?.data?.result);
      setSearchist(priorityMappingListData?.data?.result);
    } catch (err) {
      setLoading({ isLoading: false, value: "getPriorityMappingLList" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "getPriorityMappingLList" });
    }
  };
  const WorkQueueType = JSON.parse(sessionStorage.getItem("commonField"))?.find(
    (wqty) => wqty.typeGroupCode === "WQTYP"
  )?.fieldValues;
  const handleSearch = (event) => {
    setSearchText(event.target.value);
    const data = WorkQueueType?.filter((data) =>
      data.value.toString()?.includes(event.target.value.toLowerCase())
    );
    const filteredData = priorityMappingList.filter((item) => {
      return (
        item?.priorityScale
          ?.toString()
          .includes(event.target.value.toLowerCase()) ||
        item?.workQueueType
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item?.fbdBucket
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item?.docBucket
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item?.insuranceBalanceForm
          ?.toString()
          ?.includes(event.target.value.toLowerCase()) ||
        item?.insuranceBalanceTo
          ?.toString()
          ?.includes(event.target.value.toLowerCase()) ||
        item?.followupDateBucket
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
    });
    setSearchist(filteredData);
  };
  const handleDragStart = (e, id, index) => {
    e.dataTransfer.setData("text/plain", id.toString(), index);
    setDraggedIndex(index);
  };

  const [draggedIndex, setDraggedIndex] = useState(null);
  const [rows, setRows] = useState(searchingList);

  const handleDragOver = (e, index) => {
    e.preventDefault();
    const dragIndex = e.dataTransfer.getData("index");
    if (index !== dragIndex) {
      const newRows = [...rows];
      const draggedRow = newRows[dragIndex];
      newRows.splice(dragIndex, 1);
      newRows.splice(index, 0, draggedRow);
      setRows(newRows);
    }
  };
  const handleDragEnd = () => {
    setDraggedIndex(null);
  };
  const handleDrop = (e, dropId) => {
    const dragId = parseInt(e.dataTransfer.getData("text/plain"), 10);
    if (dragId !== Number(dropId)) {
      const newData = [...searchingList];
      const dragIndex = newData.findIndex(
        (item) => Number(item.sequence) === dragId
      );
      const dropIndex = newData.findIndex(
        (item) => Number(item.sequence) === Number(dropId)
      );

      const [removed] = newData.splice(dragIndex, 1);
      newData.splice(dropIndex, 0, removed);
      // setPriorityMappingList(newData);
      UpdateMainSequence({
        initialSequence: dragId,
        latestSequence: Number(dropId),
      });
    }
  };
  const UpdateMainSequence = async (data) => {
    try {
      const priorityMappingListData = await axiosPrivate.post(
        `Setting/update-task-priority-sequence`,
        data
      );
      setPriorityMappingList(priorityMappingListData?.data?.result);
      setSearchist(priorityMappingListData?.data?.result);
    } catch (err) {
      console.error(err);
    }
  };
  const headers = [
    "",
    "",
    "Priority Scale",
    "Work Queue Type",
    "FBD Bucket",
    "DOC Bucket",
    "Ins. Balance From",
    "Ins. Balance To",
    "Follow up date bucket",
    "Action",
  ];
  return (
    <React.Fragment>
      <AddPriorityMapping
        show={showAddPriority}
        closeFunction={setShowAddPriority}
        setPriorityMappingList={setPriorityMappingList}
        setSearchist={setSearchist}
      />
      <UpdatePriority
        show={showUpdatePriority?.showBool}
        setShowUpdatePriority={setShowUpdatePriority}
        priorityId={showUpdatePriority.priorityId}
        setPriorityMappingList={setPriorityMappingList}
        setSearchist={setSearchist}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-1">Priority Mapping</h4>
          <h5 className="fs-14 text-secondary">
            Define the Payment mode for the bill payment Settlement
          </h5>
        </div>

        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-1"
            onClick={() => setShowAddPriority(true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add Priority Mapping</span>
          </Button>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body>
          {loading.isLoading && loading.value === "getPriorityMappingLList" ? (
            <ShimmerLoader
              colNames={headers?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Priority Scale</th>
                  <th>Work Queue Type</th>
                  <th>FBD Bucket</th>
                  <th>DOC Bucket</th>
                  <th>Ins. Balance From</th>
                  <th>Ins. Balance To</th>
                  <th>Follow up date bucket</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {searchingList?.map((row, index) => (
                  <tr
                    draggable
                    key={index}
                    onDragOver={(e) => handleDragOver(e, index)}
                    onDrop={(e) => handleDrop(e, row.sequence)}
                    onDragStart={(e) => handleDragStart(e, row.sequence, index)}
                    onDragEnd={handleDragEnd}
                    className={index === draggedIndex ? "dragging" : ""}
                  >
                    <td
                      style={{
                        transition: "transform 0.3s ease-in-out",
                        cursor: "move",
                      }}
                    ></td>
                    <td>
                      <span>
                        <Link className="dragable-option text-dark">
                          <i className="ri-drag-move-2-line"></i>
                        </Link>
                      </span>
                    </td>
                    <td>{row.priorityScale}</td>
                    {/* <td>{row.sequence}</td> */}
                    <td>
                      <div>
                        {
                          JSON.parse(sessionStorage.getItem("commonField"))
                            ?.find((wqty) => wqty.typeGroupCode === "WQTYP")
                            ?.fieldValues?.find(
                              (field) => field.id === row.workQueueType
                            )?.value
                        }
                      </div>
                    </td>
                    <td>
                      <div>
                        {
                          JSON.parse(sessionStorage.getItem("commonField"))
                            ?.find((wqty) => wqty.typeGroupCode === "BTYP")
                            ?.fieldValues?.find(
                              (field) => field.id === row.fbdBucket
                            )?.value
                        }
                      </div>
                    </td>
                    <td>
                      <div>
                        {
                          JSON.parse(sessionStorage.getItem("commonField"))
                            ?.find((wqty) => wqty.typeGroupCode === "BTYP")
                            ?.fieldValues?.find(
                              (field) => field.id === row.docBucket
                            )?.value
                        }
                      </div>
                    </td>
                    <td>
                      <div>{row.insuranceBalanceForm}</div>
                    </td>
                    <td>
                      <div>
                        <div>{row.insuranceBalanceTo}</div>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div>
                        {
                          JSON.parse(sessionStorage.getItem("commonField"))
                            ?.find((wqty) => wqty.typeGroupCode === "FBTYP")
                            ?.fieldValues?.find(
                              (field) => field.id === row.followupDateBucket
                            )?.value
                        }
                      </div>
                    </td>
                    <td className="w-0">
                      <div className="w-1">
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="primary"
                            type="button"
                            className="btn-icon"
                            onClick={() =>
                              setShowUpdatePriority({
                                showBool: true,
                                priorityId: row?.id,
                              })
                            }
                          >
                            <i className="ri-pencil-line"></i>
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
