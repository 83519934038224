import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Form, ButtonGroup, Button } from "react-bootstrap";
import "./case-disposition.scss";
import Select from "react-select";
import actionMode from "../../../../Json/DecisionTree/action-mode.json";
import useAuth from "../../../../hooks/useAuth";
import { axiosPrivate } from "../../../../api/axios";
import ToastifyService from "../../../_common/ToastifyService";
import moment from "moment";

function DAuthorization(props) {
  let myRef = useRef();
  const initialState = {
    statusName: "",
    dateOdDenial: "",
    claimId: "",
    IsPATokenAvail: "",
    IsPAReq: "",
    PAReqConfirm: "",
    IsPAadjud: "",
    PositivePolicy: "",
    IsCareTokenMatch: "",
    PATokenOnClaim: "",
    PATokenApprov: "",
    CPTTokenMatch: "",
    PAWindowMatch: "",
    isInNetwork: "",
    IsAuthReq: "",
    IsAuthFile: "",
    IsAuthOnCall: "",
    doesDOSFall: "",
    IsRepProcessing: "",
    ETA: "",
    isOtherInsurance: "",
    InsuranceName: "",
    IsPolicyActive: "",
    ActionId: "",
    AppealLimit: "",
    AppealAddress: "",
    CCTFL: "",
    CallId: "",
    RetroAuth: "",
  };
  const [formData, setFormData] = useState(initialState);
  const { auth } = useAuth();
  const [selectedSubStatus, setSelectedSubStatus] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedActionMode, setSelectedActionMode] = useState("");
  const [subStatusList, setSubStatusList] = useState([]);
  // HANDLE ACTION FUNCTION
  const [filteredActionData, setFilteredActionData] = useState([]);
  // HANDLE ACTION MODE FUNCTION
  const [filteredActionModeData, setFilteredActionModeData] = useState([]);
  const [selectedToggle, setSelectedToggle] = useState("");
  const [reprocessinToggle, setReprocessingToggle] = useState("");
  const [isAuthRequired, setIsAuthRequired] = useState("");
  const [IsPATokenAvailable, setIsPATokenAvailable] = useState("");
  const [IsPARequired, setIsPARequired] = useState("");
  const [IsPAReqConfirm, setIsPAReqConfirm] = useState("");
  const [IsPAAdjud, setIsPAAdjud] = useState("");
  const [IsPositivepolicy, setIsPositivepolicy] = useState("");
  const [IsCareTokenMatching, setIsCareTokenMatching] = useState("");
  const [IsPATokenOnClaim, setIsPATokenOnClaim] = useState("");
  const [IsPATokenApprove, setIsPATokenApprove] = useState("");
  const [IsCPTTokenMatch, setIsCPTTokenMatch] = useState("");
  const [IsPAWindowMatch, setIsPAWindowMatch] = useState("");
  const [InRetroAuthLimit, setInRetroAuthLimit] = useState("");
  const [isAuthFile, setIsAuthFile] = useState("");
  const [isAuthOnCall, setIsAuthOnCall] = useState("");
  const [selectedSubmitType, setSelectedSubmitTypes] = useState("");
  const [submitList, setSubmitList] = useState([]);
  const [AppealSent, setAppealSent] = useState(false);
  const [retroAuthlimitValue, setRetroAuthlimitValue] = useState("");
  console.log("retroAuthlimitValue", retroAuthlimitValue);
  const subStatusJson = [
    {
      value: 1,
      label: "Claim billed with matching PA Token to In network payer",
    },
    {
      value: 2,
      label: "PA not adjudicated. Retro limit Available",
    },
    {
      value: 3,
      label: "Claim billed with matching PA Token to OON payer",
    },
    {
      value: 4,
      label: "CPT Mismatch to PA token. Retro limit Available",
    },
    {
      value: 5,
      label: "PA Token Mismatch to Care Token",
    },
    {
      value: 6,
      label: "PA Token Missing on claim",
    },
    {
      value: 7,
      label: "PA Token not approved. Negative Policy",
    },
    {
      value: 8,
      label: "PA Token not approved. Positive Policy",
    },
    {
      value: 9,
      label: "CPT Mismatch to PA token. Positive Policy",
    },
    {
      value: 10,
      label: "CPT Mismatch to PA token. Negative Policy",
    },
    {
      value: 11,
      label: "PA window Mismatch to PA token. Retro limit Available",
    },
    {
      value: 12,
      label: "PA window Mismatch. Positive Policy",
    },
    {
      value: 13,
      label: "PA window Mismatch. Negative Policy",
    },
    {
      value: 14,
      label: "PA Request Denied. Positive Policy",
    },
    {
      value: 15,
      label: "PA request Denied. Negative Policy",
    },
    {
      value: 16,
      label: "PA not adjudicated. Positive Policy",
    },
    {
      value: 17,
      label: "Auth not required",
    },
    {
      value: 18,
      label: "Auth not required In network payer",
    },
    {
      value: 19,
      label: "Auth not required  to OON payer",
    },
    {
      value: 20,
      label:
        "Need to Submit corrected claim to Payer. Updated PA token on Claim",
    },
  ];
  const SubmitTypeJson = [
    {
      value: 1,
      label: "FAX",
    },
    {
      value: 2,
      label: "Website",
    },
    {
      value: 3,
      label: "Mail",
    },
  ];

  const RequiredDocsJson = [
    {
      value: 1,
      label: "Order Form",
    },
    {
      value: 2,
      label: "Result",
    },
    {
      value: 3,
      label: "Progress Notes",
    },
    {
      value: 4,
      label: "Claim Form",
    },
    {
      value: 5,
      label: "WOL",
    },
  ];
  const ActionJson = [
    {
      value: 1,
      label: "Reprocessed",
    },
    {
      value: 2,
      label: "Appeal Sent",
    },
    {
      value: 3,
      label: "Rebilled Corrected Claim",
    },
    {
      value: 4,
      label: "Sent to PA team",
    },
  ];
  const ActionModeJson = [
    {
      value: 1,
      label: "PAPER",
      followUpdays: 35,
      followUPBy: "AR",
    },
    {
      value: 2,
      label: "TASK",
      followUpdays: 3,
      followUPBy: "BILLING",
    },
    {
      value: 3,
      label: "ELECTRONIC",
      followUpdays: 22,
      followUPBy: "AR",
    },
    {
      value: 4,
      label: "FAX",
      followUpdays: 22,
      followUPBy: "AR",
    },
    {
      value: 5,
      label: "WEB",
      followUpdays: 5,
      followUPBy: "AR",
    },
    {
      value: 6,
      label: "CALL",
      followUpdays: 22,
      followUPBy: "AR",
    },
  ];
  useEffect(() => {
    // // OPTION ACTION MODE DATA
    setSubmitList(SubmitTypeJson);
    // setFilteredActionModeData(ActionModeJson);
  }, []);

  const handleActionSelect = (event) => {
    setFormData({
      ...formData,
      ActionId: event.value,
      ActionName: event.label,
      ActionModeId: "",
    });
    setSelectedAction(event);
    if (event.value === 1) {
      setFilteredActionModeData([
        {
          value: 5,
          label: "WEB",
          followUpdays: 5,
          followUPBy: "AR",
        },
        {
          value: 6,
          label: "CALL",
          followUpdays: 22,
          followUPBy: "AR",
        },
      ]);
    } else if (event.value === 2) {
      setFilteredActionModeData([
        {
          value: 4,
          label: "FAX",
          followUpdays: 22,
          followUPBy: "AR",
        },
        {
          value: 5,
          label: "WEB",
          followUpdays: 5,
          followUPBy: "AR",
        },
        {
          value: 7,
          label: "MAIL",
          followUpdays: 7,
          followUPBy: "AR",
        },
      ]);
    } else if (event.value === 3) {
      setFilteredActionModeData([
        {
          value: 1,
          label: "PAPER",
          followUpdays: 35,
          followUPBy: "AR",
        },
        {
          value: 3,
          label: "ELECTRONIC",
          followUpdays: 22,
          followUPBy: "AR",
        },
      ]);
    } else if (event.value === 4) {
      setFilteredActionModeData([
        {
          value: 2,
          label: "TASK",
          followUpdays: 3,
          followUPBy: "BILLING",
        },
      ]);
    }
  };

  useEffect(() => {
    // setSelectedToggle("");
    setFormData({
      ...formData,
      status: props.selectedStatus.value,
      statusName: props.selectedStatus.label,
    });
    setSubStatusList([]);
  }, [props.selectedStatus]);
  // STATUS FUNCTION

  // SUB STATUS FUNCTION
  const handleSubStatusSelect = (event) => {
    setFormData({
      ...formData,
      SubStatus: event.value,
      subStatusName: event.label,
      ActionId: "",
      ActionModeId: "",
      followUpBy: "",
      followUpDays: "",
    });
    console.log(event);
    setSelectedAction("");

    setSelectedSubStatus(event);
    if (formData.status !== props?.selectedStatus?.value) {
      const actionsList = JSON.parse(sessionStorage.getItem("commonField"))
        ?.find((wqty) => wqty.id === formData.status)
        ?.fieldValues?.find((action) => action.id === event.value)
        ?.commonFieldActions?.map((item) => ({
          value: item.id,
          label: item.actionName,
        }));
      setFilteredActionData(actionsList);
    } else if (AppealSent) {
      setFilteredActionData([
        {
          value: 2,
          label: "Appeal Sent",
        },
      ]);
    } else if (IsCareTokenMatching === "no" || IsPATokenOnClaim === "no") {
      setFilteredActionData([
        {
          value: 3,
          label: "Rebilled Corrected Claim",
        },
      ]);
    } else if (InRetroAuthLimit === "yes") {
      setFilteredActionData([
        {
          value: 4,
          label: "Sent to PA team",
        },
      ]);
    } else if (reprocessinToggle === "Agree") {
      setFilteredActionData([
        {
          value: 1,
          label: "Reprocessed",
        },
      ]);
    } else {
      const actionsList = ActionJson.filter(
        (stts) => stts.value === event.value
      ).map((item) => ({
        value: item.value,
        label: item.label,
      }));
      setFilteredActionData(actionsList);
    }
  };

  const handleActionModeSelect = (event) => {
    setSelectedActionMode(event);
    setFormData({
      ...formData,
      ActionModeId: event.value,
      ActionModeName: event.label,
      followUpBy: event.followUPBy,
      followUpDays: event.followUpdays,
    });
  };

  const handleSubmitType = (event) => {
    setSelectedSubStatus("");
    setSelectedAction("");
    setSelectedActionMode("");
    setSelectedSubmitTypes("");
    setSelectedSubmitTypes(event);
    setFormData({ ...formData, ActionId: "" });
    setFormData({
      ...formData,
      submitType: event.value,
      FAXNumber: "",
      WebsiteLink: "",
      Address1: "",
      Address2: "",
      City: "",
      State: "",
      Zip: "",
      AttnTo: "",
      ActionId: "",
      AppealLimit: "",
      ActionModeId: "",
      followUpBy: "",
      followUpDays: "",
    });
    // setSubStatusList(subStatusJson);
  };
  const [checkboxes, setCheckboxes] = useState(
    RequiredDocsJson.reduce((acc, type) => {
      acc[type.label] = false;
      return acc;
    }, {})
  );

  // Handler to toggle the checkbox state
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    });
  };

  const handleToggle = (key, value) => {
    setSelectedSubStatus("");
    setSelectedAction("");
    setSelectedActionMode("");
    setSelectedSubmitTypes("");

    if (key === "PAtokenAvail") {
      setIsPATokenAvailable(value);
      setIsPARequired("");
      setIsCareTokenMatching("");
      setIsAuthRequired("");
      setIsPATokenOnClaim("");
      setIsPATokenApprove("");
      setIsCPTTokenMatch("");
      setIsPAWindowMatch("");
      setSubStatusList([]);
      setAppealSent("");
      setIsPAWindowMatch("");
      setIsPAReqConfirm("");
      setSelectedToggle("");
      setReprocessingToggle("");
      setSelectedSubStatus("");
      setIsPositivepolicy("");
      setIsPAAdjud("");
      setInRetroAuthLimit("");
      setRetroAuthlimitValue("");
      setFormData({ ...formData, IsPATokenAvail: value });
    } else if (key === "careTokenMatch") {
      setIsCareTokenMatching(value);
      setIsAuthRequired("");
      setIsPATokenOnClaim("");
      setIsPATokenApprove("");
      setIsCPTTokenMatch("");
      setIsPAWindowMatch("");
      setReprocessingToggle("");
      setSubStatusList([]);
      setIsPositivepolicy("");
      setSelectedSubStatus("");
      setIsPAAdjud("");
      setInRetroAuthLimit("");
      setRetroAuthlimitValue("");
      if (value === "no") {
        setSubStatusList([
          {
            value: 5,
            label: "PA Token Mismatch to Care Token",
          },
        ]);
      }
      setFormData({ ...formData, IsCareTokenMatch: value });
    } else if (key === "PATokenClaim") {
      setIsPATokenOnClaim(value);
      setIsPATokenApprove("");
      setIsAuthRequired("");
      setIsCPTTokenMatch("");
      setIsPAWindowMatch("");
      setReprocessingToggle("");
      setSelectedSubStatus("");
      setSelectedToggle("");
      setIsPositivepolicy("");
      setIsPAReqConfirm("");
      setIsPAAdjud("");
      setRetroAuthlimitValue("");
      setInRetroAuthLimit("");
      if (value === "no") {
        setSubStatusList([
          {
            value: 6,
            label: "PA Token Missing on claim",
          },
        ]);
      }
      setFormData({ ...formData, PATokenOnClaim: value });
    } else if (key === "PATokenApprov") {
      setIsPATokenApprove(value);
      setIsCPTTokenMatch("");
      setIsAuthRequired("");
      setIsPAWindowMatch("");
      setReprocessingToggle("");
      setSubStatusList([]);
      setAppealSent("");
      setSelectedSubStatus("");
      setIsPositivepolicy("");
      setIsPAAdjud("");
      setRetroAuthlimitValue("");
      setInRetroAuthLimit("");
      setFormData({ ...formData, PATokenApprov: value });
    } else if (key === "CPTTokenmatch") {
      setIsCPTTokenMatch(value);
      setIsPAWindowMatch("");
      setIsAuthRequired("");
      setReprocessingToggle("");
      setSubStatusList([]);
      setAppealSent("");
      setSelectedToggle("");
      setIsPAReqConfirm("");
      setIsPositivepolicy("");
      setIsPAAdjud("");
      setRetroAuthlimitValue("");
      setInRetroAuthLimit("");
      setFormData({ ...formData, CPTTokenMatch: value });
    } else if (key === "PAwindowmatch") {
      setIsPAWindowMatch(value);
      setSelectedToggle("");
      setIsAuthRequired("");
      setReprocessingToggle("");
      setSubStatusList([]);
      setSelectedSubStatus("");
      setAppealSent("");
      setIsPAReqConfirm("");
      setIsPositivepolicy("");
      setIsPAAdjud("");
      setRetroAuthlimitValue("");
      setInRetroAuthLimit("");
      setFormData({ ...formData, PAWindowMatch: value });
    } else if (key === "claimProcess") {
      setSelectedToggle(value);
      setReprocessingToggle("");
      setSubStatusList([]);
      setSelectedSubStatus("");
      setIsAuthRequired("");
      setAppealSent("");
      setIsPositivepolicy("");
      setIsPAAdjud("");
      setRetroAuthlimitValue("");
      setInRetroAuthLimit("");
      setFormData({ ...formData, isInNetwork: value });
    } else if (key === "askReprocess") {
      setSelectedSubmitTypes("");
      setReprocessingToggle(value);
      setIsAuthRequired("");
      setSelectedSubStatus("");
      setRetroAuthlimitValue("");
      setInRetroAuthLimit("");
      setIsPositivepolicy("");
      if (value === "Disagree") {
        setAppealSent(true);
      } else {
        setAppealSent("");
      }
      if (IsPAReqConfirm === "no" && reprocessinToggle === "yes") {
        setSubStatusList([
          {
            value: 17,
            label: "Auth not required",
          },
        ]);
      } else if (IsPAReqConfirm === "no") {
        selectedToggle === "yes"
          ? setSubStatusList([
              {
                value: 18,
                label: "Auth not required In network payer",
              },
            ])
          : setSubStatusList([
              {
                value: 19,
                label: "Auth not required  to OON payer",
              },
            ]);
      } else if (IsPAWindowMatch === "yes") {
        const data = selectedToggle === "yes" ? 1 : 3;
        // if (value === "Agree") {
        const subStatusList = subStatusJson
          ?.filter((stts) => stts.value === data)
          .map((item) => ({
            value: item.value,
            label: item.label,
          }));
        setSubStatusList(subStatusList);
      }
      setFormData({ ...formData, IsRepProcessing: value });
    } else if (key === "IsAuth") {
      setIsAuthRequired(value);
      setSelectedSubStatus("");
      // setSelectedToggle("");
      setIsPositivepolicy("");
      setFormData({ ...formData, IsAuthReq: value });
    } else if (key === "isAuthFile") {
      setIsAuthFile(value);
      setSelectedToggle("");
      setSelectedSubStatus("");
      setIsPAReqConfirm("");
      setIsPositivepolicy("");
      setFormData({ ...formData, IsAuthFile: value });
    } else if (key === "AuthCall") {
      setIsAuthOnCall(value);
      setSelectedToggle("");
      setSelectedSubStatus("");
      setIsPAReqConfirm("");
      setIsPositivepolicy("");
      setFormData({ ...formData, IsAuthOnCall: value });
    } else if (key === "PAReq") {
      setIsPARequired(value);
      setIsAuthRequired("");
      setReprocessingToggle("");
      setIsPAWindowMatch("");
      setSelectedSubStatus("");
      setIsPAReqConfirm("");
      setAppealSent("");
      setSelectedToggle("");
      setIsPositivepolicy("");
      setIsPAAdjud("");
      setInRetroAuthLimit("");
      setIsPATokenApprove("");
      setRetroAuthlimitValue("");
      setInRetroAuthLimit("");
      setFormData({ ...formData, IsPAReq: value });
    } else if (key === "PAAdjud") {
      setIsPAAdjud(value);
      setSelectedSubStatus("");
      setSelectedToggle("");
      setAppealSent("");
      setIsPAReqConfirm("");
      setIsPositivepolicy("");
      setFormData({ ...formData, IsPAReq: value });
    } else if (key === "Positivepolicy") {
      setIsPositivepolicy(value);
      setSelectedToggle("");
      setSelectedSubStatus("");
      setAppealSent(value);
      setSelectedToggle("");
      setSelectedSubmitTypes("");
      if (IsPATokenApprove === "no") {
        value === "yes"
          ? setSubStatusList([
              {
                value: 8,
                label: "PA Token not approved. Positive Policy",
              },
            ])
          : setSubStatusList([
              {
                value: 7,
                label: "PA Token not approved. Negative Policy",
              },
            ]);
      } else if (IsCPTTokenMatch === "no") {
        value === "yes"
          ? setSubStatusList([
              {
                value: 9,
                label: "CPT Mismatch to PA token. Positive Policy",
              },
            ])
          : setSubStatusList([
              {
                value: 10,
                label: "CPT Mismatch to PA token. Negative Policy",
              },
            ]);
      } else if (IsPAWindowMatch === "no") {
        value === "yes"
          ? setSubStatusList([
              {
                value: 12,
                label: "PA window mismatch. Positive Policy",
              },
            ])
          : setSubStatusList([
              {
                value: 13,
                label: "PA window mismatch. Negative Policy",
              },
            ]);
      } else if (IsPAAdjud === "yes") {
        value === "yes"
          ? setSubStatusList([
              {
                value: 14,
                label: "PA Request Denied. Positive Policy",
              },
            ])
          : setSubStatusList([
              {
                value: 15,
                label: "PA Request Denied. Negative Policy",
              },
            ]);
      } else if (IsPAAdjud === "no") {
        value === "yes"
          ? setSubStatusList([
              {
                value: 14,
                label: "PA not adjudicated. Positive Policy",
              },
            ])
          : setSubStatusList([
              {
                value: 15,
                label: "PA not adjudicated. Negative Policy",
              },
            ]);
      }
      setFormData({ ...formData, IsPositivePolicy: value });
    } else if (key === "PAReqConf") {
      setIsPAReqConfirm(value);
      setSelectedSubStatus("");
      setSelectedAction("");
      setReprocessingToggle("");
      setSelectedToggle("");
      setAppealSent("");
      setIsPAAdjud("");
      setInRetroAuthLimit("");
      setIsPATokenApprove("");
      setRetroAuthlimitValue("");
      setFormData({ ...formData, PAReqConfirm: value });
    } else if (key === "InRetro") {
      setInRetroAuthLimit(value);
      setSelectedToggle("");
      if (value === "yes") {
        if (IsCPTTokenMatch === "no") {
          setSubStatusList([
            {
              value: 4,
              label: "CPT Mismatch to PA token. Retro limit Available",
            },
          ]);
        } else if (IsPAAdjud === "no" || IsPAReqConfirm === "yes") {
          setSubStatusList([
            {
              value: 2,
              label: "PA not adjudicated. Retro limit Available",
            },
          ]);
        } else if (IsPAWindowMatch === "no") {
          setSubStatusList([
            {
              value: 11,
              label: "PA window Mismatch to PA token. Retro limit Available",
            },
          ]);
        }
      }

      setAppealSent("");
      setFormData({ ...formData, PAReqConfirm: value });
    }
    setFormData({
      ...formData,
      submitType: "",
      FAXNumber: "",
      WebsiteLink: "",
      Address1: "",
      Address2: "",
      City: "",
      State: "",
      Zip: "",
      AttnTo: "",
      ActionId: "",
      AppealLimit: "",
      isInNetwork: "",
      IsAuthReq: "",
      IsAuthFile: "",
      RetroAuth: "",
      IsAuthOnCall: "",
      doesDOSFall: "",
      IsRepProcessing: "",
      ETA: "",
      isOtherInsurance: "",
      ActionModeId: "",
      InsuranceName: "",
      IsPolicyActive: "",
      AppealAddress: "",
      CCTFL: "",
      CallId: "",
      followUpBy: "",
      followUpDays: "",
    });
    setCheckboxes(
      RequiredDocsJson.reduce((acc, type) => {
        acc[type.label] = true;
        return acc;
      }, {})
    );
  };

  const handleSaveDisposition = async () => {
    const formObj = {
      caseId: props?.taskDetails?.id,
      visitId: props?.taskDetails?.visitId,
      dispositionBody: JSON.stringify(formData),
      dispositionRemark: stickyNoteContent,
      modifiedBy: auth.id,
    };
    console.log(formObj);
    try {
      const response = await axiosPrivate.post(
        "Task/case-disposition",
        formObj
      );
      props?.setPendingTasks(response.data.result.pendingTasks);
      props?.setTaskSummary(
        response?.data?.result?.taskBucketSummary?.taskBucketDetails
      );
      props?.setTeamLeadQueries(response?.data?.result?.tlQueries);
      props?.setParkedTasks(response?.data?.result?.parkedTasks);
      props?.setMyTask(response?.data?.result);
      props.setShowTasksDetails({
        showTaskDetails: false,
        taskId: "",
      });
      ToastifyService.success("Case Disposed SuccessFully");
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const [stickyNoteContent, setStickyNoteContent] = useState("");

  const generateDynamicContent = () => {
    let content = "";

    content += `Visit Number ${props?.taskDetails?.visitId} `;
    if (formData.status) {
      content += `Claim Denied as ${formData.statusName}. `;
    }
    if (formData.dateOdDenial) {
      content += `On ${moment(formData.dateOdDenial).format("MM/DD/YYYY")}. `;
    }
    if (formData.claimId) {
      content += `Claim # ${formData.claimId}. `;
    }
    if (IsPATokenAvailable === "yes") {
      content += `As per system review PA token is Available. `;
    }
    if (IsPATokenAvailable === "no") {
      content += `As per system review PA token is Not Available. `;
    }
    if (IsPARequired === "yes") {
      content += `As per PA sheet PA is Required. `;
    }
    if (IsPARequired === "no") {
      content += `As per PA sheet PA is Not Required `;
    }
    if (IsPAAdjud === "yes") {
      content += `The PA request was Adjudicated. `;
    }
    if (IsPAAdjud === "no") {
      content += `The PA request was Not Adjudicated `;
    }
    if (IsCareTokenMatching === "yes") {
      content += `The PA token does match with care token. `;
    }
    if (IsCareTokenMatching === "no") {
      content += `The PA token does not match with care token. `;
    }
    if (IsPATokenOnClaim === "yes") {
      content += `PA token is Available on Claim. `;
    }
    if (IsPATokenOnClaim === "no") {
      content += `PA token is not Available on Claim. `;
    }
    if (IsPATokenApprove === "yes") {
      content += `PA token is Approved. `;
    }
    if (IsPATokenApprove === "no") {
      content += `PA token is not Approved. `;
    }
    if (IsCPTTokenMatch === "yes") {
      content += `The CPT Codes Match. `;
    }
    if (IsCPTTokenMatch === "no") {
      content += `The CPT Codes not Match. `;
    }
    if (IsPAWindowMatch === "yes") {
      content += `The PA window Match with claim. `;
    }
    if (IsPAWindowMatch === "no") {
      content += `The PA window Do Not Match with claim. `;
    }
    if (IsPAReqConfirm === "yes") {
      content += `As per payer PA is Required. `;
    }
    if (IsPAReqConfirm === "no") {
      content += `As per payer PA is Not Required. `;
    }
    if (retroAuthlimitValue !== "") {
      content += `Retro Auth limit is ${retroAuthlimitValue} Days. `;
    }
    if (InRetroAuthLimit === "yes") {
      content += `DOS is In Retro Limit. `;
    }
    if (InRetroAuthLimit === "no") {
      content += `DOS is Out of Retro Limit. `;
    }

    if (IsPositivepolicy === "yes") {
      content += `This is a Positive case. Need to appeal with appropriate positive policy No PA, Medical Necessity Letter along with Requisition and Report  `;
    }
    if (IsPositivepolicy === "no") {
      content += `This is a Negative case. Need to appeal with appropriate not positive policy No PA, Medical Necessity Letter along with Requisition and Report `;
    }
    if (selectedToggle === "yes") {
      content += `The payer is In network. `;
    } else if (selectedToggle === "no") {
      content += `The payer is Out of network. `;
    }
    if (reprocessinToggle === "Agree") {
      content += `The payer Agree to reprocess. `;
    } else if (reprocessinToggle === "Disagree") {
      content += `The payer denied to reprocess. `;
    }
    if (isAuthRequired === "Agree") {
      content += `We Do have the Auth approval letter from payer. `;
    } else if (isAuthRequired === "Disagree") {
      content += `We Do Not have the Auth approval letter from payer. `;
    }
    if (reprocessinToggle === "Agree") {
      content += `It can take upto ${formData.ETA} Days to reprocess. `;
    }

    if (formData.submitType === 1) {
      content += `Need to submit appeal by Fax. `;
      if (formData.FAXNumber) {
        content += `At Fax # ${formData.FAXNumber}. `;
      }
      if (formData.AttnTo) {
        content += `Attn. to. ${formData.AttnTo}. `;
      }
    } else if (formData.submitType === 2) {
      content += `Need to submit appeal by WEB. `;
      if (formData.WebsiteLink) {
        content += `At ${formData.WebsiteLink}. `;
      }
      if (formData.AttnTo) {
        content += `Attn. to. ${formData.AttnTo}. `;
      }
    } else if (formData.submitType === 3) {
      content += `Need to submit appeal by MAIL at ${formData.Address1} ${formData.Address2} ${formData.City} ${formData.State} ${formData.Zip}. `;
      if (formData.AttnTo) {
        content += `Attn. to. ${formData.AttnTo}. `;
      }
    }
    if (
      selectedSubmitType !== "" &&
      !Object.values(checkboxes).every((value) => value === false)
    ) {
      content += "Document required on appeal are  ";
      Object.entries(checkboxes).forEach(([label, value]) => {
        if (value) {
          content += `${label}, `;
        }
      });
    }

    if (formData.IsAuthReq === "no") {
      content += `As per Payer Auth is not required. `;
    } else if (formData.IsAuthReq === "yes") {
      content += `As per Payer Auth is required. `;
      if (formData.IsAuthFile === "Disagree") {
        content += `Checked System, Auth not found on file. `;
      } else if (formData.IsAuthFile === "Agree") {
        content += `Checked System, Auth found on file. `;
      }
    }
    if (formData.IsAuthFile === "Agree") {
      if (formData.IsAuthOnCall === "Disagree") {
        content += `Rep denied to take Auth details over call. `;
      } else if (formData.IsAuthOnCall === "Agree") {
        content += `Rep agreed to take Auth details over call. `;
      }
    }

    if (formData.AppealLimit) {
      content += `Appeal limit is ${formData.AppealLimit} Days. `;
    }
    if (formData.AppealAddress) {
      content += `Appeal from ${formData.AppealAddress}. `;
    }
    if (formData.IsAuthOnCall === "Disagree") {
      content += `Need to update Auth on claim and resubmit a corrected claim. AUTH AVAILABLE. SUBMIT CORRECTED CLAIM Claim can be submitted within ${formData.CCTFL}. `;
    }
    if (formData.CallId) {
      content += `Reprocess reference# is  ${formData.CallId}. `;
    }
    if (formData.ActionId === 1) {
      content += `Claim sent back for reprocess. `;
    } else if (formData.ActionId === 2) {
      content += `Appeal sent to insurance. `;
    } else if (formData.ActionId === 3) {
      content += `Corrected claim sent to insurance. `;
    } else if (formData.ActionId === 4) {
      content += `Sent to PA team `;
    }

    // Update the state with the generated dynamic content
    setStickyNoteContent(content);
  };
  // Call the function to generate the initial content when component mounts
  useEffect(() => {
    generateDynamicContent();
  }, [props.taskDetails, formData, checkboxes, retroAuthlimitValue]);

  console.log(checkboxes);
  const [zipError, setZipError] = useState("");

  const handleZipChange = (e) => {
    const zipValue = e.target.value;
    // Allow only numbers and hyphen
    const validZip = zipValue.replace(/[^0-9-]/g, "");

    // Validate the zip code format
    const zipPattern = /^(\d{5}|\d{5}-\d{4})$/;
    if (!zipPattern.test(validZip) && validZip.length > 0) {
      setZipError(
        "Zip code must be 5 digits or 9 digits in the format 12345-6789"
      );
    } else {
      setZipError("");
    }

    setFormData({
      ...formData,
      Zip: validZip,
    });
  };
  return (
    <React.Fragment>
      <Col md={12}>
        {/* Denial Status Start */}

        <Row className="mb-4 align-items-center">
          <Col md={12}>
            <Form.Label>Date Of Denial:</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="date"
              name="dateOdDenial"
              value={formData.dateOdDenial}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  dateOdDenial: e.target.value,
                })
              }
            ></Form.Control>
          </Col>
        </Row>
        <Row className="mb-4 align-items-center">
          <Col md={12}>
            <Form.Label>Claim #:</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Enter Claim #"
              name="claimId"
              value={formData.claimId}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  claimId: e.target.value,
                })
              }
            ></Form.Control>
          </Col>
        </Row>

        <Row className="mb-4 align-items-center">
          <Col md={12}>
            <Form.Label>Does Natera have PA token Available?</Form.Label>
          </Col>
          <Col>
            <ButtonGroup>
              <Button
                variant={
                  IsPATokenAvailable === "yes" ? "primary" : "outline-primary"
                }
                onClick={() => handleToggle("PAtokenAvail", "yes")}
              >
                <input
                  type="radio"
                  name="IsPATokenAvail"
                  className="btn-check"
                  autoComplete="off"
                  checked={IsPATokenAvailable === "yes"}
                  onChange={() => handleToggle("PAtokenAvail", "yes")}
                />
                Available
              </Button>
              <Button
                variant={
                  IsPATokenAvailable === "no" ? "primary" : "outline-primary"
                }
                onClick={() => handleToggle("PAtokenAvail", "no")}
              >
                <input
                  type="radio"
                  name="IsPATokenAvail"
                  className="btn-check"
                  autoComplete="off"
                  checked={IsPATokenAvailable === "no"}
                  onChange={() => handleToggle("PAtokenAvail", "no")}
                />
                Not Available
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        {IsPATokenAvailable === "yes" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Does it match the Care Token ?</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    IsCareTokenMatching === "yes"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => handleToggle("careTokenMatch", "yes")}
                >
                  <input
                    type="radio"
                    name="IsCareTokenMatch"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsCareTokenMatching === "yes"}
                    onChange={() => handleToggle("careTokenMatch", "yes")}
                  />
                  Match
                </Button>
                <Button
                  variant={
                    IsCareTokenMatching === "no" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("careTokenMatch", "no")}
                >
                  <input
                    type="radio"
                    name="IsCareTokenMatch"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsCareTokenMatching === "no"}
                    onChange={() => handleToggle("careTokenMatch", "no")}
                  />
                  Not Match
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {IsPATokenAvailable === "no" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Is PA required ?</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    IsPARequired === "yes" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("PAReq", "yes")}
                >
                  <input
                    type="radio"
                    name="IsPAReq"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPARequired === "yes"}
                    onChange={() => handleToggle("PAReq", "yes")}
                  />
                  Required
                </Button>
                <Button
                  variant={
                    IsPARequired === "no" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("PAReq", "no")}
                >
                  <input
                    type="radio"
                    name="IsPAReq"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPARequired === "no"}
                    onChange={() => handleToggle("PAReq", "no")}
                  />
                  Not Required
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {IsPARequired === "no" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Confirm with payer for PA requiement ?</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    IsPAReqConfirm === "yes" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("PAReqConf", "yes")}
                >
                  <input
                    type="radio"
                    name="PAReqConfirm"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPAReqConfirm === "yes"}
                    onChange={() => handleToggle("PAReqConf", "yes")}
                  />
                  Required
                </Button>
                <Button
                  variant={
                    IsPAReqConfirm === "no" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("PAReqConf", "no")}
                >
                  <input
                    type="radio"
                    name="PAReqConfirm"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPAReqConfirm === "no"}
                    onChange={() => handleToggle("PAReqConf", "no")}
                  />
                  Not Required
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {IsPARequired === "yes" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Was PA request Adjudicated ?</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={IsPAAdjud === "yes" ? "primary" : "outline-primary"}
                  onClick={() => handleToggle("PAAdjud", "yes")}
                >
                  <input
                    type="radio"
                    name="IsPAadjud"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPAAdjud === "yes"}
                    onChange={() => handleToggle("PAAdjud", "yes")}
                  />
                  Adjudicated
                </Button>
                <Button
                  variant={IsPAAdjud === "no" ? "primary" : "outline-primary"}
                  onClick={() => handleToggle("PAAdjud", "no")}
                >
                  <input
                    type="radio"
                    name="IsPAadjud"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPAAdjud === "no"}
                    onChange={() => handleToggle("PAAdjud", "no")}
                  />
                  Not Adjudicated
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {IsCareTokenMatching === "yes" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Was PA token on Claim ?</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    IsPATokenOnClaim === "yes" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("PATokenClaim", "yes")}
                >
                  <input
                    type="radio"
                    name="PATokenOnClaim"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPATokenOnClaim === "yes"}
                    onChange={() => handleToggle("PATokenClaim", "yes")}
                  />
                  Availble
                </Button>
                <Button
                  variant={
                    IsPATokenOnClaim === "no" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("PATokenClaim", "no")}
                >
                  <input
                    type="radio"
                    name="PATokenOnClaim"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPATokenOnClaim === "no"}
                    onChange={() => handleToggle("PATokenClaim", "no")}
                  />
                  Not Available
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {IsPATokenOnClaim === "yes" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Was PA token an approved Token ?</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    IsPATokenApprove === "yes" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("PATokenApprov", "yes")}
                >
                  <input
                    type="radio"
                    name="PATokenApprov"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPATokenApprove === "yes"}
                    onChange={() => handleToggle("PATokenApprov", "yes")}
                  />
                  Approved
                </Button>
                <Button
                  variant={
                    IsPATokenApprove === "no" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("PATokenApprov", "no")}
                >
                  <input
                    type="radio"
                    name="PATokenApprov"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPATokenApprove === "no"}
                    onChange={() => handleToggle("PATokenApprov", "no")}
                  />
                  Not Approved
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {IsPATokenApprove === "yes" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Do CPT codes match the PA Token ?</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    IsCPTTokenMatch === "yes" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("CPTTokenmatch", "yes")}
                >
                  <input
                    type="radio"
                    name="CPTTokenMatch"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsCPTTokenMatch === "yes"}
                    onChange={() => handleToggle("CPTTokenmatch", "yes")}
                  />
                  Match
                </Button>
                <Button
                  variant={
                    IsCPTTokenMatch === "no" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("CPTTokenmatch", "no")}
                >
                  <input
                    type="radio"
                    name="CPTTokenMatch"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsCPTTokenMatch === "no"}
                    onChange={() => handleToggle("CPTTokenmatch", "no")}
                  />
                  Do Not Match
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {IsCPTTokenMatch === "yes" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Does PA window Match ?</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    IsPAWindowMatch === "yes" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("PAwindowmatch", "yes")}
                >
                  <input
                    type="radio"
                    name="PAWindowMatch"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPAWindowMatch === "yes"}
                    onChange={() => handleToggle("PAwindowmatch", "yes")}
                  />
                  Match
                </Button>
                <Button
                  variant={
                    IsPAWindowMatch === "no" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("PAwindowmatch", "no")}
                >
                  <input
                    type="radio"
                    name="PAWindowMatch"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPAWindowMatch === "no"}
                    onChange={() => handleToggle("PAwindowmatch", "no")}
                  />
                  Do Not Match
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}

        {(IsPAReqConfirm === "yes" ||
          IsPAWindowMatch === "no" ||
          IsCPTTokenMatch === "no" ||
          IsPAAdjud === "no") && (
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>What is the Retro Auth limit ? </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Retro Auth limit"
                  name="retroAuthlimitValue"
                  value={retroAuthlimitValue}
                  onChange={(e) => setRetroAuthlimitValue(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>
                  Is the date of Service in Retro Auth Limit ?
                </Form.Label>
              </Col>
              <Col>
                <ButtonGroup>
                  <Button
                    variant={
                      InRetroAuthLimit === "yes" ? "primary" : "outline-primary"
                    }
                    onClick={() => handleToggle("InRetro", "yes")}
                  >
                    <input
                      type="radio"
                      name="InRetroLimit"
                      className="btn-check"
                      autoComplete="off"
                      checked={InRetroAuthLimit === "yes"}
                      onChange={() => handleToggle("InRetro", "yes")}
                    />
                    In Retro Limit
                  </Button>
                  <Button
                    variant={
                      InRetroAuthLimit === "no" ? "primary" : "outline-primary"
                    }
                    onClick={() => handleToggle("InRetro", "no")}
                  >
                    <input
                      type="radio"
                      name="InRetroLimit"
                      className="btn-check"
                      autoComplete="off"
                      checked={InRetroAuthLimit === "no"}
                      onChange={() => handleToggle("InRetro", "no")}
                    />
                    Out of Retro Limit
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </>
        )}

        {(IsPAAdjud === "yes" ||
          InRetroAuthLimit === "no" ||
          IsPATokenApprove === "no") && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Is this a positive policy case ?</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    IsPositivepolicy === "yes" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("Positivepolicy", "yes")}
                >
                  <input
                    type="radio"
                    name="IsPositivePolicy"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPositivepolicy === "yes"}
                    onChange={() => handleToggle("Positivepolicy", "yes")}
                  />
                  Positive Policy
                </Button>
                <Button
                  variant={
                    IsPositivepolicy === "no" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("Positivepolicy", "no")}
                >
                  <input
                    type="radio"
                    name="IsPositivePolicy"
                    className="btn-check"
                    autoComplete="off"
                    checked={IsPositivepolicy === "no"}
                    onChange={() => handleToggle("Positivepolicy", "no")}
                  />
                  Negative Policy
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}

        {/* <Row className="mb-4 align-items-center">
          <Col md={12}>
            <Form.Label>Is Auth Required?</Form.Label>
          </Col>
          <Col>
            <ButtonGroup>
              <Button
                variant={
                  isAuthRequired === "yes" ? "primary" : "outline-primary"
                }
                onClick={() => handleToggle("IsAuth", "yes")}
              >
                <input
                  type="radio"
                  name="IsAuthReq"
                  className="btn-check"
                  autoComplete="off"
                  checked={isAuthRequired === "yes"}
                  onChange={() => handleToggle("IsAuth", "yes")}
                />
                Yes
              </Button>
              <Button
                variant={
                  isAuthRequired === "no" ? "primary" : "outline-primary"
                }
                onClick={() => handleToggle("IsAuth", "no")}
              >
                <input
                  type="radio"
                  name="IsAuthReq"
                  className="btn-check"
                  autoComplete="off"
                  checked={isAuthRequired === "no"}
                  onChange={() => handleToggle("IsAuth", "no")}
                />
                No
              </Button>
            </ButtonGroup>
          </Col>
        </Row> */}
        {(IsPAWindowMatch === "yes" || IsPAReqConfirm === "no") && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Is Payer In network or OON:</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    selectedToggle === "yes" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("claimProcess", "yes")}
                >
                  <input
                    type="radio"
                    name="isInNetwork"
                    className="btn-check"
                    autoComplete="off"
                    checked={selectedToggle === "yes"}
                    onChange={() => handleToggle("claimProcess", "yes")}
                  />
                  In Network
                </Button>
                <Button
                  variant={
                    selectedToggle === "no" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleToggle("claimProcess", "no")}
                >
                  <input
                    type="radio"
                    name="isInNetwork"
                    className="btn-check"
                    autoComplete="off"
                    checked={selectedToggle === "no"}
                    onChange={() => handleToggle("claimProcess", "no")}
                  />
                  Out Of Network
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {/* Does DOS falls between effective and termination Date : yes  (start)*/}
        {selectedToggle !== "" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Check with payer for reprocess:</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    reprocessinToggle === "Agree"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => handleToggle("askReprocess", "Agree")}
                >
                  <input
                    type="radio"
                    name="options"
                    className="btn-check"
                    autoComplete="off"
                    checked={reprocessinToggle === "Agree"}
                    onChange={() => handleToggle("askReprocess", "Agree")}
                  />
                  Agree to reprocess
                </Button>
                <Button
                  variant={
                    reprocessinToggle === "Disagree"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => handleToggle("askReprocess", "Disagree")}
                >
                  <input
                    type="radio"
                    name="options"
                    className="btn-check"
                    autoComplete="off"
                    checked={reprocessinToggle === "Disagree"}
                    onChange={() => handleToggle("askReprocess", "Disagree")}
                  />
                  Denied to reprocess
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {/* Agree REPROCESSING start */}
        {reprocessinToggle === "Agree" && (
          // (isAuthFile === "Agree" && isAuthOnCall === "Agree")) &&
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Reprocess Timelines:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="ETA for reprocess"
                  name="ETA"
                  value={formData.ETA}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ETA: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Reprocess Reference #:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Call Referrence #"
                  name="CallId"
                  value={formData.CallId}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      CallId: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </>
        )}
        {/* Agre REPROCESSING end  */}

        {/* Disagree REPROCESSING start */}
        {reprocessinToggle === "Disagree" && (
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>
                  Do we have Auth approval letter from Insurance ?
                </Form.Label>
              </Col>
              <Col>
                <ButtonGroup>
                  <Button
                    variant={
                      isAuthRequired === "Agree" ? "primary" : "outline-primary"
                    }
                    onClick={() => handleToggle("IsAuth", "Agree")}
                  >
                    <input
                      type="radio"
                      name="options"
                      className="btn-check"
                      autoComplete="off"
                      checked={isAuthRequired === "Agree"}
                      onChange={() => handleToggle("IsAuth", "Agree")}
                    />
                    Do have
                  </Button>
                  <Button
                    variant={
                      isAuthRequired === "Disagree"
                        ? "primary"
                        : "outline-primary"
                    }
                    onClick={() => handleToggle("IsAuth", "Disagree")}
                  >
                    <input
                      type="radio"
                      name="options"
                      className="btn-check"
                      autoComplete="off"
                      checked={isAuthRequired === "Disagree"}
                      onChange={() => handleToggle("IsAuth", "Disagree")}
                    />
                    Do Not Have
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>

            {/* <Row className="mb-4 ">
              <Col md={12}>
                <Form.Label>Appeals Address:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Enter Appeals Address"
                  name="AppealAddress"
                  value={formData.AppealAddress}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      AppealAddress: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Required Documents:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Required Documents"
                />
              </Col>
            </Row>

            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Call Referrence #:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Call Referrence #"
                  name="CallId"
                  value={formData.CallId}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      CallId: e.target.value,
                    })
                  }
                />
              </Col>
            </Row> */}
          </>
        )}
        {/* Disagree REPROCESSING end */}

        {isAuthFile === "Agree" && isAuthOnCall === "Disagree" && (
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>CC TFL:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="Enter CC TFL"
                  name="CCTFL"
                  value={formData.CCTFL}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      CCTFL: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Call Referrence #:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Call Referrence #"
                  name="CallId"
                  value={formData.CallId}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      CallId: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </>
        )}
        {AppealSent && (
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>
                  How to Submit? :<span className="text-danger">*</span>
                </Form.Label>
              </Col>
              <Col>
                <Select
                  onChange={handleSubmitType}
                  options={submitList}
                  name="submitType"
                  value={selectedSubmitType}
                  // isClearable
                />
              </Col>
            </Row>

            {selectedSubmitType.value === 1 && (
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>FAX #:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter FAX Number"
                    name="FAXNumber"
                    value={formData.FAXNumber}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        FAXNumber: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            )}
            {selectedSubmitType.value === 2 && (
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Website Address:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Website Address"
                    name="WebsiteLink"
                    value={formData.WebsiteLink}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        WebsiteLink: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            )}
            {selectedSubmitType.value === 3 && (
              <>
                <Row className="mb-4 align-items-center">
                  <Col md={12}>
                    <Form.Label>Mail Address 1:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Enter Mail Address 1"
                      name="Address1"
                      value={formData.Address1}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Address1: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                  <Col md={12}>
                    <Form.Label>Mail Address 2:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Enter Mail Address "
                      name="Address2"
                      value={formData.Address2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Address2: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                  <Col md={12}>
                    <Form.Label>City:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Enter City "
                      name="City"
                      value={formData.City}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          City: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                  <Col md={12}>
                    <Form.Label>State:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Enter State"
                      name="State"
                      value={formData.State}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          State: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                <Row className="mb-4 align-items-center">
                  <Col md={12}>
                    <Form.Label>Zip Code:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Enter Zip Code"
                      name="Zip"
                      value={formData.Zip}
                      onChange={handleZipChange}
                    />
                    {zipError && <p style={{ color: "red" }}>{zipError}</p>}
                  </Col>
                </Row>
              </>
            )}
            {selectedSubmitType !== "" && (
              <>
                <Row className="mb-4 align-items-center">
                  <Col md={12}>
                    <Form.Label>Att. to:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Enter Department name"
                      name="AttnTo"
                      value={formData.AttnTo}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          AttnTo: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Form>
                    <Col md={12}>
                      <Form.Label>Requested Documents:</Form.Label>
                    </Col>
                    <Col>
                      {RequiredDocsJson.map((type) => (
                        <div className="mb-3" key={type.label}>
                          <Form.Check
                            name={type.label}
                            label={type.label}
                            checked={checkboxes[type.label]}
                            onChange={handleCheckboxChange}
                            type="checkbox"
                          />
                        </div>
                      ))}
                    </Col>
                  </Form>
                </Row>
                <Row className="mb-4 align-items-center">
                  <Col md={12}>
                    <Form.Label>Appeal Limit:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      min={1}
                      placeholder="Enter Appeal Limit"
                      name="AppealLimit"
                      value={formData.AppealLimit}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          AppealLimit: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}

        {subStatusList && subStatusList.length !== 0 && (
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>
                  Sub Status <span className="text-danger">*</span>
                </Form.Label>
              </Col>
              <Col>
                <Select
                  onChange={handleSubStatusSelect}
                  options={subStatusList}
                  name="SubStatus"
                  value={selectedSubStatus}
                  isClearable
                />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Action:</Form.Label>
              </Col>
              <Col>
                <Select
                  onChange={handleActionSelect}
                  options={filteredActionData}
                  name="ActionId"
                  value={selectedAction}
                />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Action Mode:</Form.Label>
              </Col>
              <Col>
                <Select
                  onChange={handleActionModeSelect}
                  options={filteredActionModeData}
                  name="ActionModeId"
                  value={selectedActionMode}
                />
              </Col>
            </Row>
          </>
        )}
      </Col>

      <Col md={12}>
        <Row className="mb-4">
          <Col md={12}>
            <Form.Label>Automated Notes:</Form.Label>
          </Col>
          <Col>
            <div
              className="disposition-sticky-note"
              contentEditable={true}
              suppressContentEditableWarning={true}
            >
              <span className="text-dark fw-semibold fs-12 lh-28">
                {/* Concatenate dynamic content and user-editable content */}
                {stickyNoteContent}
                <span
                  className="user-editable-content"
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                ></span>
              </span>
            </div>
          </Col>
        </Row>
      </Col>
      <div className="decision-tree-save-bttn">
        <Button variant="primary" onClick={handleSaveDisposition}>
          Save
        </Button>
      </div>
    </React.Fragment>
  );
}

export default DAuthorization;
