import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import Select from "react-select";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import LoadingSpinner from "../_common/LoadingSpinner";

export default function MyCasesFilters(props) {
  const { auth } = useAuth();
  const [taskEnginPublishList, setTaskEnginePublishList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [formattedOptions, setFormattedOptions] = useState("");
  const [patientName, setPatientName] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const axiosPrivate = useAxiosPrivate();

  const selectTaskBucketOptions = taskEnginPublishList?.map((Published) => ({
    label: Published?.taskBucketName,
    value: Published?.id,
  }));
  useEffect(() => {
    getTaskEnginePublishList();
    setFormattedOptions("");
    setSelectedOptions([]);
  }, []);

  const getTaskEnginePublishList = async () => {
    try {
      const taskEnginePublishListData = await axiosPrivate.get(
        `TaskEngine/published-task-buckets`
      );
      setTaskEnginePublishList(taskEnginePublishListData?.data?.result);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    const formatted = selected.map((option) => `'${option.value}'`).join(",");
    setFormattedOptions(formatted);
  };
  const Applytask = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "filterTasks",
      });
      const data = {
        userId: auth.id,
        taskBucketId: formattedOptions,
        patientName: patientName,
      };
      const FilterTaskList = await axiosPrivate.post(`Task/filter-task`, data);
      props?.setPendingTasks(FilterTaskList?.data?.result);
      props?.closeFunction();
    } catch (err) {
      console.error(err);
      setLoading({
        isLoading: false,
        value: "filterTasks",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "filterTasks",
      });
    }
  };

  const clear = () => {
    setSelectedOptions([]);
    setPatientName("");
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="fs-16 text-dark">
            <i className="ri-equalizer-line align-middle"></i>{" "}
            <span className="align-middle">Filter</span>
          </Offcanvas.Title>
          <Button variant="default" className="text-danger" onClick={clear}>
            Clear
          </Button>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="mb-4">
            <Form.Label>Task Bucket Name</Form.Label>
            <Select
              options={selectTaskBucketOptions}
              isSearchable={true}
              isMulti={true}
              name="taskBucketId"
              value={selectedOptions}
              onChange={handleChange}
            />
          </div>

          <div className="mb-4">
            <Form.Label>Patient Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Patient Name"
              name="patientName"
              value={patientName}
              onChange={(e) => setPatientName(e.target.value)}
            />
          </div>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            disabled={loading.isLoading && loading?.value === "filterTasks"}
            className="fs-14 me-2 d-flex align-items-center"
            onClick={Applytask}
          >
            {loading.isLoading && loading?.value === "filterTasks" ? (
              <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Apply</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
