import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Commonfield from "../../../infrastructure/core/Commonfield";
import ToastifyService from "../../_common/ToastifyService";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function AddPriorityMapping(props) {
  const { auth } = useAuth();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const axiosPrivate = useAxiosPrivate();
  const workQueueTypeList = JSON.parse(
    sessionStorage.getItem("commonField")
  )?.find((wqty) => wqty.typeGroupCode === "WQTYP")?.fieldValues;
  const fbdDosBucketList = JSON.parse(
    sessionStorage.getItem("commonField")
  )?.find((wqty) => wqty.typeGroupCode === "BTYP")?.fieldValues;
  const followUpDateBucketList = JSON.parse(
    sessionStorage.getItem("commonField")
  )?.find((wqty) => wqty.typeGroupCode === "FBTYP")?.fieldValues;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    setFormData({
      workQueueType: Commonfield.defaultValue,
      fbdBucket: Commonfield.defaultValue,
      docBucket: Commonfield.defaultValue,
      insuranceBalanceForm: 0,
      insuranceBalanceTo: 0,
      followupDateBucket: Commonfield.defaultValue,
    });
  }, []);
  const handleSubmit = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "CreatePriority",
      });
      const priorityMappingListData = await axiosPrivate.post(
        `Setting/create-task-priority`,
        {
          ...formData,
          status: Commonfield.status.active,
          createdBy: auth?.id,
        }
      );
      props?.setPriorityMappingList(priorityMappingListData?.data?.result);
      props?.setSearchist(priorityMappingListData?.data?.result);
      props.closeFunction();
      ToastifyService.success("Priority Mapping Done Successfully");
    } catch (err) {
      setLoading({
        isLoading: false,
        value: "CreatePriority",
      });
      // console.error(err);
      ToastifyService.error("Oops! Something Went Wrong");
    } finally {
      setLoading({
        isLoading: false,
        value: "CreatePriority",
      });
    }
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Create New Priority Mapping
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Priority Scale <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="Enter Priority Scale"
                  name="priorityScale"
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Work Queue Type</Form.Label>
                <Form.Select name="workQueueType" onChange={handleChange}>
                  <option selected value={Commonfield.defaultValue}>
                    Select Work Queue Type
                  </option>
                  {workQueueTypeList?.map((wqty) => (
                    <option value={wqty.id}>{wqty.value}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>FBD Bucket</Form.Label>
                <Form.Select name="fbdBucket" onChange={handleChange}>
                  <option selected value={Commonfield.defaultValue}>
                    Select FBD Bucket
                  </option>
                  {fbdDosBucketList?.map((fbd) => (
                    <option value={fbd.id}>{fbd.value}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>DOS Bucket</Form.Label>
                <Form.Select name="docBucket" onChange={handleChange}>
                  <option selected value={Commonfield.defaultValue}>
                    Select DOS Bucket
                  </option>
                  {fbdDosBucketList?.map((fbd) => (
                    <option value={fbd.id}>{fbd.value}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Insurance Bal. From</Form.Label>
                <Form.Control
                  name="insuranceBalanceForm"
                  onChange={handleChange}
                  type="number"
                  min={0}
                  placeholder="Enter Insurance Bal. From"
                />
              </div>
            </Col>

            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Insurance Bal. To</Form.Label>
                <Form.Control
                  name="insuranceBalanceTo"
                  onChange={handleChange}
                  type="number"
                  min={0}
                  placeholder="Enter Insurance Bal. To"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Follow up date bucket</Form.Label>
                <Form.Select name="followupDateBucket" onChange={handleChange}>
                  <option selected value={Commonfield.defaultValue}>
                    Select Follow up date bucket
                  </option>
                  {followUpDateBucketList?.map((fbd) => (
                    <option value={fbd.id}>{fbd.value}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "CreatePriority"}
            onClick={handleSubmit}
          >
            {/* <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create</span> */}
            {loading.isLoading && loading?.value === "CreatePriority" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              "Create"
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
