import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { axiosPrivate } from "../../../api/axios";
import ToastifyService from "../../_common/ToastifyService";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function ManuallyAssignComponent(props) {
  const [selectManualluAssignOptions, setselectManualluAssignOptions] =
    useState([]);
  const [selectedTask, setSelectedTask] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const handleChange = (selected) => {
    setSelectedUser(selected);
  };
  useEffect(() => {
    getUserGroupList();
    setSelectedTask(props?.selectedTask);
  }, [props?.selectedTask]);
  const getUserGroupList = async () => {
    try {
      //   setLoading({ isLoading: true, value: "getUserGroupList" });
      const userGroupListData = await axiosPrivate.get(`Users/users`);
      const data = userGroupListData?.data?.result
        ?.filter(
          (value) => value.role === "8eed8d89-1c21-4e34-9d19-72670f84db80"
        )
        ?.map((data) => ({
          value: data.id,
          label: data.fullName,
        }));
      setselectManualluAssignOptions(data);
    } catch (err) {
      console.error(err);
    }
  };
  const AssisgnManually = async () => {
    setLoading({
      isLoading: true,
      value: "AssisgnManually",
    });
    const task = selectedTask.map((value) => ({
      caseId: value.caseId,
      visitId: value.visitID,
    }));
    const formObj = {
      assignTo: selectedUser.value,
      assignedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      cases: task,
    };
    try {
      const response = await axiosPrivate.post(
        "Task/request-new-task",
        formObj
      );
      props?.setpendingInventory(response.data.result);
      setSelectedTask([]);
      props?.setSelectedTask([]);
      setSelectedUser("");
      props.closeFunction();
      ToastifyService.success("Task Assigned SuccessFully");
    } catch (error) {
      setLoading({
        isLoading: false,
        value: "AssisgnManually",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "AssisgnManually",
      });
    }
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Manually Assign
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={10}>
              <div className="mb-4">
                <Form.Label>
                  Choose Agent <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  options={selectManualluAssignOptions}
                  isSearchable={true}
                  value={selectedUser}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "AssisgnManually"}
            onClick={AssisgnManually}
          >
            {loading.isLoading && loading?.value === "AssisgnManually" ? (
              <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Assign</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
