import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ToastifyService from "../_common/ToastifyService";
import LoadingSpinner from "../_common/LoadingSpinner";
import logo from "../../assets/img/logo/main-logo.png";

function ResetPassword() {
  document.body.classList.remove("sidebar-show");
  const { setAuth, auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });

  const [formData, setFormData] = useState({
    email: auth.user,
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    if (auth.user) {
      setFormData({ ...formData, email: auth.user });
    }
  }, [auth.user]);
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      setLoading({
        isLoading: true,
        value: "resetPassword",
      });
      const response = await axiosPrivate.post("Auth/reset-password", formData);
      ToastifyService.success("Password Reset Successful");
      const accessToken = response?.data?.accessToken;
      const refreshToken = response?.data?.refreshToken;
      const roles = response?.data?.roles;
      const id = response?.data?.id;
      const isResetDefaultPass = response?.data?.isResetDefaultPass;
      const name = response?.data?.name;
      const userName = formData.username;
      const password = formData.password;
      setAuth({
        userName,
        password,
        roles,
        accessToken,
        refreshToken,
        id,
        isResetDefaultPass,
        name,
      });
      // navigate("/reports/task-dashboard");
      if (response?.data?.roles[0] === "Employee") {
        navigate("/MyTask/manage");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error(error);
      ToastifyService.error("Oops! Something Went Wrong");
      setLoading({
        isLoading: false,
        value: "resetPassword",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "resetPassword",
      });
    }
  };

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          {/* <Link to="/account/login" className="header-logo">
            Natera
          </Link> */}
           <Link to="/" className="sidebar-logo">
          <Image src={logo} className="wt-150" />
        </Link>
          <Nav className="nav-icon">
            <Nav.Link href="">
              <i className="ri-twitter-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-github-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-dribbble-line"></i>
            </Nav.Link>
          </Nav>
        </Container>

        <div className="content">
          <Container>
            <Row className="g-2">
              <Col md="7" lg="4" className="col-wrapper">
                <Card className="card-sign">
                  <Card.Header>
                    <Card.Title>Reset your Password</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <div className="mb-4">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter your Username"
                          name="password"
                          value={formData.password}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              password: e.target.value,
                            });
                          }}
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <Form.Label className="d-flex justify-content-between">
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          placeholder="Enter your password"
                          type="password"
                          required
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              confirmPassword: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <Button
                        type="button"
                        className="btn-sign"
                        disabled={loading.isLoading && loading?.value === "resetPassword"}
                        onClick={handleResetPassword}
                      >
                        {loading.isLoading &&
                        loading?.value === "resetPassword" ? (
                          <LoadingSpinner
                            color="#ffffff"
                            size={30}
                            type={"TailSpin"}
                          />
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </Form>
                  </Card.Body>
                  {/* <Card.Footer>
                    {" "}
                    Don't have an account?{" "}
                    <Link to="/account/signup">Create an Account</Link>
                  </Card.Footer> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
