import React, { useEffect, useState } from "react";
import { Button, Form, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CreateNewUser from "./create-new-user";
import UpdateUser from "./update-user";
import ShimmerLoader from "../../_common/ShimmerLoader";

export default function UserAccounts() {
  const axiosPrivate = useAxiosPrivate();
  const [showNewUser, setShowNewUser] = useState(false);
  const [updateUser, setUpdateUser] = useState({
    showBool: false,
    userId: "",
  });
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const [userAccountList, setUserAccountList] = useState([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    getUserAccountList();
  
  }, []);
  const getUserAccountList = async () => {
    try {
      setLoading({ isLoading: true, value: "getUserAccountList" });
      const userAccountListData = await axiosPrivate.get(`Users/users`);
      setUserAccountList(userAccountListData?.data?.result);
    } catch (err) {
      // console.error(err);
      setLoading({ isLoading: false, value: "getUserAccountList" });
    } finally {
      setLoading({ isLoading: false, value: "getUserAccountList" });
    }
  };
  const columns = [
    {
      name: "Full Name",
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) => <div>{row.fullName}</div>,
    },
    {
      name: "Status",
      selector: (row) => row.statusDesc,
      sortable: true,
      cell: (row) => <div>{row.statusDesc}</div>,
    },
    // {
    //   name: "Skill Set",
    //   selector: (row) => row.skillSet,
    //   sortable: true,
    //   cell: (row) => <div>{row.skillSet}</div>,
    // },
    {
      name: "User Group",
      selector: (row) => {
        row?.taskGroups?.map((taskGroup) => taskGroup.groupName).join(", ");
      },
      sortable: true,
      cell: (row) => (
        <div>
          {row?.taskGroups?.map((taskGroup) => taskGroup.groupName).join(", ")}
        </div>
      ),
    },
    {
      name: "Role",
      selector: (row) => row.roleDesc,
      sortable: true,
      cell: (row) => <div>{row.roleDesc}</div>,
    },
    {
      name: "InActive Date",
      selector: (row) => row.inActiveDate,
      sortable: true,
      cell: (row) => (
        <div>
          {row.inActiveDate === "0001-01-01T00:00:00" ? "-" : row.inActiveDate}
        </div>
      ),
    },
    {
      name: "Created By",

      selector: (row) => row.createdByName,
      sortable: true,
      cell: (row) => <div>{row.createdByName}</div>,
    },
    {
      name: "Vender",

      selector: (row) => row.vendorName,
      sortable: true,
      cell: (row) => <div>{row.vendorName}</div>,
    },
    {
      name: "Action",
      sortable: true,
      cell: (row) => (
        <div className="w-1">
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="button"
              className="btn-icon"
              onClick={() => setUpdateUser({ showBool: true, userId: row?.id })}
            >
              <i className="ri-pencil-line"></i>
            </Button>
          </div>
        </div>
      ),
    },
  ];
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const filteredData = userAccountList.filter((item) => {
    return (
      item?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.statusDesc?.toLowerCase().includes(searchText.toLowerCase()) ||
      // item?.skillSet?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.taskGroups
        ?.map((taskGroup) => taskGroup.groupName)
        .join(", ")
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.roleDesc?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.inActiveDate?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.createdByName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.vendorName?.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  return (
    <React.Fragment>
      <CreateNewUser
        show={showNewUser}
        setUserAccountList={setUserAccountList}
        closeFunction={setShowNewUser}
      />
      <UpdateUser
        show={updateUser?.showBool}
        setUpdateUser={setUpdateUser}
        setUserAccountList={setUserAccountList}
        userId={updateUser?.userId}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-0">User Accounts</h4>
        </div>

        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-1"
            onClick={() => setShowNewUser(true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add User Account</span>
          </Button>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          {loading.isLoading && loading.value === "getUserAccountList" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
