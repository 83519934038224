import React, { useEffect, useState } from "react";
import { Form, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ToastifyService from "../../_common/ToastifyService";
import ShimmerLoader from "../../_common/ShimmerLoader";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function SAAGMasterComponent() {
  const axiosPrivate = useAxiosPrivate();
  const [saagMasterList, setSaagMasterList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [swagloading, setSwagLoading] = useState({
    isLoading: false,
    value: "",
  });

  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });

  const columns = [
    {
      name: "Skill Set",
      selector: (row) => row.skillSet,
      sortable: true,
      cell: (row) => <div>{row.skillSet}</div>,
    },
    {
      name: "Follow UP DAYS",
      selector: (row) => row.followUpDays,
      sortable: true,
      cell: (row) => <div>{row.followUpDays}</div>,
    },
    {
      name: "Next Follow UP BY",
      selector: (row) => row.nextFollowUpBy,
      sortable: true,
      cell: (row) => <div>{row.nextFollowUpBy}</div>,
    },
    {
      name: "Effectiveness Matrics",
      selector: (row) => row.effectivenessMatrics,
      sortable: true,
      cell: (row) => <div>{row.effectivenessMatrics}</div>,
    },
    {
      name: "Sub Effectiveness",
      selector: (row) => row.subEffectiveness,
      sortable: true,
      cell: (row) => <div>{row.subEffectiveness}</div>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <div>{row.status}</div>,
    },
    {
      name: "Sub Status",

      selector: (row) => row.subStatus,
      sortable: true,
      cell: (row) => <div>{row.subStatus}</div>,
    },
  ];

  useEffect(() => {
    getSaagMasterList();
  }, []);

  const getSaagMasterList = async () => {
    try {
      setLoading({ isLoading: true, value: "getSaagMasterList" });
      const saagMasterList = await axiosPrivate.get(`Setting/saag-masters`);
      setSaagMasterList(saagMasterList?.data?.result);
    } catch (err) {
      setLoading({ isLoading: false, value: "getSaagMasterList" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "getSaagMasterList" });
    }
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setSwagLoading({
      isLoading: true,
      value: "UploadSwag",
    });
    if (
      file &&
      (file.type === "text/csv" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel")
    ) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const saagMasterList = await axiosPrivate.post(
          `Setting/upload-saag-master`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setSaagMasterList(saagMasterList?.data?.result);
        ToastifyService.success("SAAG Uploaded Successfully");
      } catch (error) {
        // console.error(error);
        ToastifyService.error("Oops! Something Went Wrong");
        setSwagLoading({
          isLoading: false,
          value: "UploadSwag",
        });
      } finally {
        setSwagLoading({
          isLoading: false,
          value: "UploadSwag",
        });
      }
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const filteredData = saagMasterList.filter((item) => {
    return (
      item?.skillSet?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.followUpDays?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.nextFollowUpBy?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.effectivenessMatrics
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.subEffectiveness
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.status?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.subStatus?.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  return (
    <React.Fragment>
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-1">SAAG Master</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <label
            htmlFor="upload-input"
            className="btn btn-primary d-flex align-items-center gap-1"
            disabled={
              swagloading.isLoading && swagloading?.value === "UploadSwag"
            }
          >
            {swagloading.isLoading && swagloading?.value === "UploadSwag" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              <>
                <input
                  id="upload-input"
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileUpload(e)}
                />

                <i className="ri-upload-line fs-16 lh-1"></i>
                <span className="d-sm-inline">Upload SAAG</span>
              </>
            )}
          </label>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table" >
          {loading.isLoading && loading.value === "getSaagMasterList" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
