const defaultValue = "00000000-0000-0000-0000-000000000000";
const status = {
  active: "4e0073a0-2337-4acf-bbd7-6b265bed7f01",
};
const taskRuleStatus = {
  published: "48f67ff4-6152-4a15-9294-476f09a73e77",
  draft: "48f67ff4-6152-4a15-9294-476f09a73e76",
};
const pendingQuerySts = {
  payerClosed: "48F67FF4-6152-4A15-9294-476F09A71E08",
  workLater: "48F67FF4-6152-4A15-9294-476F09A71E09",
  TLQuery: "48F67FF4-6152-4A15-9294-476F09A71E10",
};
const userRoles={
  teamLead:'22ff3810-fc3b-4714-9920-0508a67a6e3d'
}
const parkStatus={
  payerClosed:'48F67FF4-6152-4A15-9294-476F09A71E08',
  tlQuery:"48F67FF4-6152-4A15-9294-476F09A71E10",
  workLater:"48F67FF4-6152-4A15-9294-476F09A71E09"
}
export default {
  defaultValue,
  status,
  taskRuleStatus,
  pendingQuerySts,
  userRoles,
  parkStatus
};
