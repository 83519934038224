import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Commonfield from "../../../infrastructure/core/Commonfield";
import ToastifyService from "../../_common/ToastifyService";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function UpdatePriority(props) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [formData, setFormData] = useState({});
  const [priorityMappingData, setPriorityMappingData] = useState({});
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const workQueueTypeList = JSON.parse(
    sessionStorage.getItem("commonField")
  )?.find((wqty) => wqty.typeGroupCode === "WQTYP")?.fieldValues;
  const fbdDosBucketList = JSON.parse(
    sessionStorage.getItem("commonField")
  )?.find((wqty) => wqty.typeGroupCode === "BTYP")?.fieldValues;
  const followUpDateBucketList = JSON.parse(
    sessionStorage.getItem("commonField")
  )?.find((wqty) => wqty.typeGroupCode === "FBTYP")?.fieldValues;
  useEffect(() => {
    if (props?.priorityId) {
      getPriorityMappingDetails(props?.priorityId);
    }
  }, [props?.priorityId]);
  const getPriorityMappingDetails = async (priorityId) => {
    try {
      const priorityMappingData = await axiosPrivate.get(
        `Setting/task-priority-details/${priorityId}`
      );
      setFormData(priorityMappingData?.data?.result);
      setPriorityMappingData(priorityMappingData?.data?.result);
    } catch (err) {
      // console.error(err);
    }
  };
  const handleSubmit = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "EditPriority",
      });
      const priorityMappingListData = await axiosPrivate.post(
        `Setting/update-task-priority`,
        {
          ...formData,
          status: Commonfield.status.active,
          createdBy: auth?.id,
        }
      );
      props?.setPriorityMappingList(priorityMappingListData?.data?.result);
      props?.setSearchist(priorityMappingListData?.data?.result);
      props?.setShowUpdatePriority({ showBool: false, priorityId: "" });
      ToastifyService.success("Priority Mapping Updated Successfully");
    } catch (err) {
      // console.error(err);
      ToastifyService.error("Oops! Something Went Wrong");
      setLoading({
        isLoading: false,
        value: "EditPriority",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "EditPriority",
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={() => props?.setShowUpdatePriority({ showBool: false, priorityId: "" })} placement="end" className="w-40">
        <Offcanvas.Header>
          <Offcanvas.Title className="fs-16 text-dark">{priorityMappingData?.priorityScale}</Offcanvas.Title>
          <button type="button" class="btn-close" aria-label="Close" onClick={() => props?.setShowUpdatePriority({ showBool: false, priorityId: "" })}></button>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Priority Scale <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="Enter Priority Scale"
                  name="priorityScale"
                  onChange={handleChange}
                  value={formData.priorityScale}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Work Queue Type</Form.Label>
                <Form.Select
                  name="workQueueType"
                  onChange={handleChange}
                  value={formData.workQueueType}
                >
                  <option selected value={Commonfield.defaultValue}>
                    Select Work Queue Type
                  </option>
                  {workQueueTypeList?.map((wqty) => (
                    <option value={wqty.id}>{wqty.value}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>FBD Bucket</Form.Label>
                <Form.Select
                  name="fbdBucket"
                  onChange={handleChange}
                  value={formData.fbdBucket}
                >
                  <option selected value={Commonfield.defaultValue}>
                    Select FBD Bucket
                  </option>
                  {fbdDosBucketList?.map((fbd) => (
                    <option value={fbd.id}>{fbd.value}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>DOS Bucket</Form.Label>
                <Form.Select
                  name="docBucket"
                  onChange={handleChange}
                  value={formData.docBucket}
                >
                  <option selected value={Commonfield.defaultValue}>
                    Select DOS Bucket
                  </option>
                  {fbdDosBucketList?.map((fbd) => (
                    <option value={fbd.id}>{fbd.value}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Insurance Bal. From</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="Enter Insurance Bal. From"
                  name="insuranceBalanceForm"
                  onChange={handleChange}
                  value={formData.insuranceBalanceForm}
                />
              </div>
            </Col>

            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Insurance Bal. To</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="Enter Insurance Bal. To"
                  name="insuranceBalanceTo"
                  onChange={handleChange}
                  value={formData.insuranceBalanceTo}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Follow up date bucket</Form.Label>
                <Form.Select
                  name="followupDateBucket"
                  onChange={handleChange}
                  value={formData.followupDateBucket}
                >
                  <option selected value={Commonfield.defaultValue}>
                    Select Follow up date bucket
                  </option>
                  {followUpDateBucketList?.map((fbd) => (
                    <option value={fbd.id}>{fbd.value}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "EditPriority"}
            onClick={handleSubmit}
          >
            {loading.isLoading && loading?.value === "EditPriority" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
