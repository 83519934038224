import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, ButtonGroup, Button } from "react-bootstrap";
import "./case-disposition.scss";
import Select from "react-select";
import actionMode from "../../../../Json/DecisionTree/action-mode.json";
import useAuth from "../../../../hooks/useAuth";
import { axiosPrivate } from "../../../../api/axios";
import DCoverage from "./dCoverage";
import MedicalRecordsDispose from "./medicalRecordsdispose";
import DAuthorization from "./dAuthorization";
import ToastifyService from "../../../_common/ToastifyService";

export default function CaseDisposition(props) {
  const { auth } = useAuth();
  const statusJson = [
    {
      value: 1,
      label: "D_COVERAGE_TERMED",
    },
    {
      value: 2,
      label: "D_MEDICAL_RECORDS",
    },
    {
      value: 3,
      label: "D_AUTHORIZATION",
    },
    { value: 4, label: "P CLAIM PAID" },
    { value: 5, label: "D INCLUSIVE BUNDLED" },
    { value: 6, label: "P APPLIED TO OFFSET" },
    { value: 7, label: "D PHYSICIAN SIGN MISSING" },
    { value: 8, label: "P PAID TO PATIENT" },
    { value: 9, label: "D PHYSICIAN NAME MISSING OR INCORRECT ON REQ MR" },
    { value: 10, label: "D HOSPICE" },
    { value: 11, label: "D REFERRING PROVIDER NOT ELIGIBLE" },
    { value: 12, label: "D ADD INFO FROM PATIENT" },
    { value: 13, label: "P UNDER PAID" },
    { value: 14, label: "D MR" },
    { value: 15, label: "C CALL BACK OR VM" },
    { value: 16, label: "D FOR WAIVER OF LIABILITY" },
    { value: 17, label: "D NON COVERED CHARGE" },
    { value: 18, label: "D PT NOT IDENTIFIED" },
    { value: 19, label: "D COB NOT UPDATED" },
    { value: 20, label: "C CLAIM IN PROCESS" },
    { value: 21, label: "D INVALID POS" },
    { value: 22, label: "Z NO APPROPRIATE SAAG" },
    { value: 23, label: "D MEDICAL NECESSITY" },
    { value: 24, label: "D MAXIMUM FREQUENCY HAS BEEN MET" },
    { value: 25, label: "PROVIDER NOT ELIGIBLE" },
    { value: 26, label: "D OON PATINET RES" },
    { value: 27, label: "D TIMELY FILING" },
    { value: 28, label: "D COVERAGE TERMED" },
    { value: 29, label: "D FOR W9" },
    { value: 30, label: "D AUTHORIZATION" },
    { value: 31, label: "D INVALID DX CODE" },
    { value: 32, label: "D DUPLICATE" },
    { value: 33, label: "D OON PROVIDER RES" },
    { value: 34, label: "D PP MAX" },
    { value: 37, label: "C CNOF" },
    { value: 39, label: "D MAX BENEFIT MET" },
    { value: 40, label: "D CLIA NUMBER MISSING" },
    { value: 41, label: "D COVERED BY OTHER INSURANCE" },
    { value: 42, label: "D REFERRING PROVIDER MISSING" },
    { value: 43, label: "D SNF" },
    { value: 44, label: "P DED COINS COPAY" },
    { value: 45, label: "D DEPENDANT NOT COVERED" },
    { value: 46, label: "P PROMISE TO PAY" },
  ];
  const initialState = {
    statusId: "",
    claimId: "",
    SubstatusId: "",
    ActionId: "",
    ActionModeId: "",
    CallId: "",
    followUpBy: "",
    followUpDays: "",
  };
  const [filteredData, setFilteredData] = useState(statusJson);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSubStatus, setSelectedSubStatus] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedActionMode, setSelectedActionMode] = useState("");
  const [subStatusList, setSubStatusList] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const [filteredActionData, setFilteredActionData] = useState([]);
  const [filteredActionModeData, setFilteredActionModeData] = useState([]);
  // HANDLE ACTION FUNCTION

  // STATUS FUNCTION
  const handleStatusSelect = (event) => {
    setSelectedStatus(event);
    const subStatusList = JSON.parse(sessionStorage.getItem("commonField"))
      ?.find((wqty) => wqty.typeName === event.label)
      ?.fieldValues?.map((item) => ({
        value: item.id,
        label: item.value,
      }));
    setSubStatusList(subStatusList);
    setFilteredActionData([]);
    setFilteredActionModeData([]);
    setFormData({
      ...formData,
      statusId: event.value,
      SubstatusId: "",
      ActionId: "",
      ActionModeId: "",
      followUpBy: "",
      followUpDays: "",
    });
    setSelectedSubStatus("");
    setSelectedAction("");
    setSelectedActionMode("");
  };
  const handleActionModeSelect = (event) => {
    setSelectedActionMode(event);
    setFormData({
      ...formData,
      ActionModeId: event.value,
      ActionModeName: event.label,
      followUpBy: event.followUPBy,
      followUpDays: event.followUpdays,
    });
  };
  const handleSubStatusSelect = (event) => {
    setFormData({
      ...formData,
      SubStatus: event.value,
      subStatusName: event.label,
      ActionId: "",
      ActionModeId: "",
      followUpBy: "",
      followUpDays: "",
    });

    setSelectedAction("");
    setSelectedActionMode("");
    setSelectedSubStatus(event);
    const actionsList = JSON.parse(sessionStorage.getItem("commonField"))
      ?.find((wqty) => wqty.typeName === selectedStatus.label)
      ?.fieldValues?.find((action) => action.id === event.value)
      ?.commonFieldActions?.map((item) => ({
        value: item.id,
        label: item.actionName,
      }));
    setFilteredActionData(actionsList);
  };
  const handleActionSelect = (event) => {
    setFormData({
      ...formData,
      ActionId: event.value,
      ActionName: event.label,
      ActionModeId: "",
      followUpBy: "",
      followUpDays: "",
    });
    setSelectedAction(event);
    setSelectedActionMode("");
    const actionModeList = JSON.parse(sessionStorage.getItem("commonField"))
      ?.find((wqty) => wqty.typeName === selectedStatus.label)
      ?.fieldValues?.find((substat) => substat.id === selectedSubStatus.value)
      ?.commonFieldActions?.find((action) => action.id === event.value)
      ?.fieldActionModes?.map((item) => ({
        value: item.id,
        label: item.actionModeName,
      }));
    console.log(actionModeList);
    setFilteredActionModeData(actionModeList);
  };

  console.log(selectedStatus);
  const [stickyNoteContent, setStickyNoteContent] = useState("");

  // Function to generate the content of the disposition sticky note
  const generateStickyNoteContent = () => {
    let content = `Visit Number ${props?.taskDetails?.visitId} `;
    if (formData.statusId) {
      content += `Claim ${selectedStatus.label}. `;
    }
    if (formData.SubstatusId) {
      content += ` ${selectedSubStatus.label}. `;
    }
    if (formData.ActionId) {
      content += ` ${selectedAction.label}`;
    }
    if (formData.ActionModeId) {
      content += ` Mode of Action ${selectedActionMode.label}`;
    }

    // Update the state with the generated content
    setStickyNoteContent(content);
  };

  // Call the function to generate the initial content when component mounts
  useEffect(() => {
    generateStickyNoteContent();
  }, [props.taskDetails, formData]);

  const handleSaveDisposition = async () => {
    const formObj = {
      caseId: props?.taskDetails?.id,
      visitId: props?.taskDetails?.visitId,
      dispositionBody: JSON.stringify(formData),
      dispositionRemark: stickyNoteContent,
      modifiedBy: auth.id,
    };
    console.log(formObj);
    try {
      const response = await axiosPrivate.post(
        "Task/case-disposition",
        formObj
      );
      props?.setPendingTasks(response.data.result.pendingTasks)
      props?.setTaskSummary(
        response?.data?.result?.taskBucketSummary?.taskBucketDetails
      );
      props?.setTeamLeadQueries(response?.data?.result?.tlQueries);
      props?.setParkedTasks(response?.data?.result?.parkedTasks);
      props?.setMyTask(response?.data?.result);
      props.setShowTasksDetails({
        showTaskDetails: false,
        taskId:"",
      })
      ToastifyService.success("Case Disposed SuccessFully");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <h3 className="fs-14 fw-semibold mb-4">Actions</h3>
          <div className="position-relative mb-4">
            <Form.Label>
              Status <span className="text-danger">*</span>
            </Form.Label>
            <Select
              name="status"
              onChange={handleStatusSelect}
              options={filteredData}
              value={selectedStatus}
            />
          </div>
        </Col>
        {selectedStatus.value === 1 && (
          <DCoverage
            setShowTasksDetails={props.setShowTasksDetails}
            setPendingTasks={props?.setPendingTasks}
            taskDetails={props?.taskDetails}
            setTeamLeadQueries={props?.setTeamLeadQueries}
            setTaskSummary={props?.setTaskSummary}
            setParkedTasks={props?.setParkedTasks}
            setMyTask={props?.setMyTask}
            selectedStatus={selectedStatus}
          />
        )}
        {selectedStatus.value === 2 && (
          <MedicalRecordsDispose
            setShowTasksDetails={props.setShowTasksDetails}
            setPendingTasks={props?.setPendingTasks}
            taskDetails={props?.taskDetails}
            setTeamLeadQueries={props?.setTeamLeadQueries}
            setTaskSummary={props?.setTaskSummary}
            setParkedTasks={props?.setParkedTasks}
            setMyTask={props?.setMyTask}
            selectedStatus={selectedStatus}
          />
        )}
        {selectedStatus.value === 3 && (
          <DAuthorization
            setShowTasksDetails={props.setShowTasksDetails}
            setPendingTasks={props?.setPendingTasks}
            taskDetails={props?.taskDetails}
            setTeamLeadQueries={props?.setTeamLeadQueries}
            setTaskSummary={props?.setTaskSummary}
            setParkedTasks={props?.setParkedTasks}
            setMyTask={props?.setMyTask}
            selectedStatus={selectedStatus}
          />
        )}

        {selectedStatus !== "" &&
          selectedStatus.value !== 1 &&
          selectedStatus.value !== 2 &&
          selectedStatus.value !== 3 && (
            <>
              <Col>
                <Row className="mb-4 align-items-center">
                  <Col md={12}>
                    <Form.Label>
                      Sub Status <span className="text-danger">*</span>
                    </Form.Label>
                  </Col>
                  <Col>
                    <Select
                      onChange={handleSubStatusSelect}
                      options={subStatusList}
                      name="SubStatus"
                      value={selectedSubStatus}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                  <Col md={12}>
                    <Form.Label>Action:</Form.Label>
                  </Col>
                  <Col>
                    <Select
                      onChange={handleActionSelect}
                      options={filteredActionData}
                      name="ActionId"
                      value={selectedAction}
                    />
                  </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                  <Col md={12}>
                    <Form.Label>Action Mode:</Form.Label>
                  </Col>
                  <Col>
                    <Select
                      onChange={handleActionModeSelect}
                      options={filteredActionModeData}
                      name="ActionModeId"
                      value={selectedActionMode}
                    />
                  </Col>
                </Row>
                {/* <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Follow Up Days:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="Enter Follow Up Days"
                    name="followUpDays"
                    value={formData.followUpDays}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        followUpDays: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>Next FollowUp By:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Next FollowUp By"
                    name="followUpBy"
                    value={formData.followUpBy}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        followUpBy: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row> */}
              </Col>

              <Col md={12}>
                <Row className="mb-4">
                  <Col md={12}>
                    <Form.Label>Automated Notes:</Form.Label>
                  </Col>
                  <Col>
                    <div
                      className="disposition-sticky-note"
                      contentEditable={true}
                      suppressContentEditableWarning={true}
                    >
                      <span className="text-dark fw-semibold fs-12 lh-28">
                        {/* Concatenate dynamic content and user-editable content */}
                        {stickyNoteContent}
                        <span
                          className="user-editable-content"
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        ></span>
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <div className="decision-tree-save-bttn">
        <Button variant="primary" onClick={handleSaveDisposition}>
          Save
        </Button>
      </div>
            </>
          )}
      </Row>
    </React.Fragment>
  );
}
